<template>
    <div class="col-12 box_simulator">
      <div class="row align-items-center">
        <div class="col-12 padding_none">
          <div class="col-12 box_header">
            Quanto você deseja receber?
          </div>
        </div>
        <div class="col-12 padding_none">
          <div class="col-12 padding_none">
            <div class="col-4 padding_none pl-25">
              <money class="form-control input background" v-model="money" v-bind="moneyFormat" v-on:change="loadPaymentSimulation()"></money>
              <input type="hidden" :value="computedMoney">
            </div>
          </div>
        </div>
        <div class="col-8 padding_none border-top">
          <div class="box_text pl-25">
            Taxa de comissão AutoRadar conforme seu plano escolhido.
          </div>
          <div class="box_text box_package background pl-25">
            Plano Atual: {{ userPackage.name }} {{ userPackage.percentage_for_transaction }}%
          </div>
        </div>
        <div class="col-12 padding_none">
          <div class="col-12 box_header">
            <div class="box_text">
              VOCÊ DEVE COBRAR
            </div>
            <div class="col-4 padding_none">
              <div class="form-control input background">
                R$ {{ paymentCardSimulations.length ? floatToMoney(paymentCardSimulations[paymentCardPlot].value_demand) : '0,00' }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 padding_none">
          <div class="col-12 box_header">
            TARIFAS E PRAZOS
          </div>
        </div>
        <div class="col-8">
          <div class="box_text">
            Como você quer receber?
          </div>
          <div class="col-10 box_tarifa background">
            <i class="fa fa-barcode"></i>
            <span>Boleto à vista</span>
          </div>
          <div class="col-10 box_tarifa background">
            <div class="pull-left" style="width: 30%;">
              <i class="fa fa-credit-card"></i>
              <span>Em até:</span>
            </div>
            <div class="pull-left" style="width: 70%;">
              <select class="form-control box_input_tarifa">
                <option>Selecione</option>
                <option v-for="(p, index) in paymentCardSimulations" :key="index">
                  {{p.portion}}x de R$ {{p.value_plots}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 padding_none">
          <div class="col-12 box_header">
            <div class="box_header_text">
              PRAZO PARA RECEBIMENTO
            </div>
          </div>
        </div>
        <div class="col-6 pl-25 box_text" style="padding-bottom: 30px;">
          Seu recebimento financeiro será em 30 dias à partir da data em que o comprador receber a mercadoria e também no mesmo número de parcelas que o comprador efetuou o pagamento.
        </div>
      </div>
    </div>
</template>

<script>
  import axios from 'axios';
  import moment from 'moment';

  export default {
    props: ['user', 'authorization', 'value', 'modal', 'dataSimulator'],
    data: function() {
      return {
        loadingPackages: true,
        userPackage: [],
        money: '',
        paymentCardSimulations: [],
        paymentCardPlot: 0,
        moneyFormat: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          precision: 2,
          masked: true
        },
      };
    },
    created() {
      this.getPackagesUser();
      if (this.value){
        this.money = this.value;
      }
    },
    mounted() {
      console.log("this.dataSimulator");
      console.log(this.dataSimulator);
      if (this.dataSimulator){
        if (this.dataSimulator.index != undefined){
          this.money = this.moneyToFloat(this.dataSimulator.value);
        }
      }
    },
    computed: {
      computedMoney() {
        if (this.money != "R$ 0,00"){
          this.loadPaymentSimulation();
        }
        return this.money;
      },
    },
    methods: {
      getPackagesUser () {
        axios.get('/api/user/packages', { headers: { Authorization: this.authorization } })
        .then(response => {
          if (response.data.content){
            let data = {};
            let rate = 100;
            response.data.content.map((i) => {
              if(i.percentage_for_transaction < rate) {
                data = i;
              }
            });
            this.userPackage = data;
          }
        })
        .catch(e => {
          var msg = 'Houve um erro ao buscar o pacote do usuário! Por favor, tente novamente.'
          this.$swal("Oops!", msg, "error")
          console.error(e)
        })
        .finally(() => {
          this.loadingSolicitations = false
        })
      },

      moneyToFloat (money) {
        if(!money || !money.length) {
          return false;
        }

        if(money.indexOf('R$') != -1) {
          money = money.split('R$')[1];
        }

        let toFloatNumber = '';

        for (let letter of money) {
          if(letter !== '.' && letter !== ',') {
            toFloatNumber += letter;
          }
        }

        let integerNumbers = '';
        for (let letter of toFloatNumber.substr(0, toFloatNumber.length - 2)) {
          if(letter !== ' ') {
            integerNumbers += letter;
          }
        }
        
        let decimalNumbers = toFloatNumber.substr(toFloatNumber.length - 2, toFloatNumber.length);
        
        return parseFloat(`${integerNumbers}.${decimalNumbers}`);
      },

      floatToMoney (money) {
        if(!money) {
          return false;
        }

        money = money.toLocaleString('pt-BR');

        if(money.indexOf('.') !== -1) {
          money = money.replace('.', ',');
        }

        if(money.indexOf(' ') !== -1) {
          money = money.replace(/ /g, '.');
        }

        if(money == '00') {
          money = '0,00';
        }

        return money;
      },

      loadPaymentSimulation () {
        var money = this.moneyToFloat(this.money);

        if(!money) {
          return false;
        }

        axios.post('/api/simulation-value-asaas', { value: money }, { headers: { Authorization: this.authorization }})
        .then(response => {
          if (response.data.content.length){
            let content = response.data.content;
            

            let data = [];
            for(let item of content) {
              if(item.type === 'BOLETO') {
                //setPaymentBilletSimulation(item);
              }

              if(item.type === 'CREDIT_CARD') {
                item.value_plots = this.floatToMoney(this.moneyToFloat(item.plots[0].value));
                data.push(item);
              }
            }

            this.paymentCardSimulations = data;
            this.$parent.$emit('dataSimulatorAtt', { 'index': this.dataSimulator.index, 'value': data[0].value_demand, 'id': this.dataSimulator.id});
          }
        })
        .catch(e => {
          var msg = 'Houve um erro ao simular o pagamento! Por favor, tente novamente.'
          this.$swal("Oops!", msg, "error")
          console.error(e)
        })
        .finally(() => {
          
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .box_simulator {
    background-color: #fff;

    .padding_none {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .box_text {
      padding: 5px 0;
    }

    .box_header {
      background-color: #333;
      color: #fff;
      padding: 10px;
      padding-left: 25px;
      /*border-bottom-right-radius: 30px;
      border-top-right-radius: 30px;*/
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .box_package {
      font-weight: 600;
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px;
    }

    .input {
      border-radius: 20px;
      text-align:center;
      border: 1px solid #bbbbbb;
      color: #000;
    }

    .box_input_tarifa {
      border-radius: 20px;
      color: #000;
      padding: 5px;
      font-size: 13px;
      height: 30px;
    }

    .box_tarifa {
      border-radius: 20px;
      color: #000;
      padding: 0;
      height: 30px;
      margin-bottom: 5px;

      i{
        background-color: #f8f8f8;
        border: 1px solid #a3a3a3;
        height: 30px;
        width: 40px;
        border-radius: 15px;
        line-height: 30px;
        text-align: center;
        float: left;
      }

      span{
        line-height: 30px;
        padding-left: 5px;
      }
    }

    .background {
      background-color: #e6e6e6;
    }

    .pl-25 {
      padding-left: 25px !important;
    }

    .border-top {
      border-top: 3px solid #a3a3a3;
      margin-top: 10px;
    }
  }
</style>
