<template>
  <div class="box-avaliation">    
    <!-- MODAL VOTACAO -->
    <div class="modal fade" id="avaliationModal" tabindex="-1" role="dialog" aria-labelledby="avaliationModalLabel">
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4>Sua opinião é importante para nós!</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <div
              class="box-text">
              Avalie o serviço prestado por <span class="font-weight-bold">{{contact.name}}</span>
            </div>
            <div
              class="d-flex box-stars">
              <i
                class="fa"
                v-for="(item, index) in stars"
                :class="{'fa-star': item <= rate, 'fa-star-o': item > rate}"
                :key="index"
                @click="rate = item"
              ></i>
            </div>
            <div
              class="d-flex box_inputs">
              <textarea
                class="form-control"
                placeholder="Observação"
                v-model="observation"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <button class="btn" @click="avaliateUser()" :disabled="loadingAvaliate">
              <span v-if="!loadingAvaliate">Avaliar</span>
              <div v-if="loadingAvaliate" class="spinner-grow spinner-grow-sm" role="status"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: [
    'proposal',
    'user',
    'contact',
    'authorization',
  ],
  data: function() {
    return {
      dados: [],
      rate: 0,
      stars: [1,2,3,4,5],
      observation: '',
      loadingAvaliate: false,
    };
  },
  mounted() {
    this.modalAvaliation()
  },
  methods: {
    modalAvaliation(){
      this.rate = 0;
      this.observation = '';
      $('#avaliationModal').modal({
        show: true,
        keyboard: false,
        backdrop: 'static',
      })
    },

    avaliateUser(){
      this.loadingAvaliate = true;
      var dados = {
        'user_id': this.contact.id,
        'rate': this.rate,
        'observation': `${this.observation}`,
      }
      axios.post('/api/proposal/' + this.proposal.id + '/rate', dados, { headers: { Authorization: this.authorization }})
        .then(response => {
          console.log("avaliateUser");
          console.log(response);
          this.loadingAvaliate = false;
          this.$swal({
            title: "Sucesso!",
            text: "Avaliação registrada com sucesso.",
            imageUrl: "/img/sucesso.png",
            imageWidth: 200,
          });
          $('#avaliationModal').modal('hide');
          this.contact.rate = this.rate;
          this.rate = 0;
          this.observation = '';
        })
        .catch(e => {
          this.loadingAvaliate = false;
          var msg = 'Ocorreu um erro ao fazer a avaliação.'
          this.$swal("Oops!", msg, "error")
          console.error(e)
        })
        .finally(() => {
          
        })
    },
  }
};
</script>
<style lang="scss" scoped>
.box-avaliation {
  #avaliationModal{
    .modal-header{
      background-color: #333;
      color: #fff;

      .close{
        color: #fff;
      }
    }

    .modal-body{
      padding: 30px 1rem;
      .box-text{
        text-align: center;
        font-size: 18px;
      }

      .box-stars{
        justify-content: center;
        font-size:40px;
        padding: 20px 0;

        i{
          padding: 0 3px;
        }

      }

      .box_inputs{
        padding: 20px 0;

        .form-control{
          border-radius: 20px;
          color: #000;
          padding: 10px;
          font-size: 15px;
        }

        textarea{
          resize: none;
          height: 150px;
        }
      }
    }

    .modal-footer{
      justify-content: center;

      .btn {
        border-radius: 20px;
        text-align:center;
        border: 1px solid #01fe03;
        background-color: #01fe03;
        color: #000;
        width: 200px;
      }
    }
  }
}
</style>