<template>
	<div class="col-12 chat_solicitation">
		<div class="row align-items-center">
			<div class="col-3 box_lista">
				<div class="col-12 box_header">
					<div class="box_header_text">
						Olá {{user.name.split(" ")[0]}}
					</div>
				</div>
				<div class="col-12 box_search">
					<input type="search" v-model="search" class="form-control" placeholder="Pesquisar...">
				</div>
				<div class="col-12 box_lines">
					<div v-if="loadingSolicitations" class="d-flex justify-content-center box_loading">
						<div class="spinner-grow text-secondary" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
					<div class="col-12 line" :id="c.id" v-for="(c, index) in filteredSolicitations" :key="index+c.created_at" @click="init_chat(c)" :class="{'line_active': verifLineActive(c), 'new_solicitation': c.new}">
						<div class="box_avatar" v-if="!c.contact.image" :style="{ 'background-image': 'url(\'/img/user.jpg\')'}"></div>
						<div class="box_avatar" v-if="c.contact.image" :style="{ 'background-image': 'url(\''+c.contact.image+'\')'}"></div>
						<div class="box_info">
							<div class="box_nome">
								{{c.vehicle}} - {{c.id}}
							</div>
							<div class="box_details">
								{{c.contact.name}}
							</div>
						</div>
						<div class="box_new_messages" v-if="c.new_messages > 0">{{c.new_messages}}</div>
						<span class="badge badge-secondary float-right mr-1" v-if="c.finished">Finalizado</i></span>
					</div>
					<div class="col-12 box_sem_registro" v-if="!solicitations.length && !loadingSolicitations">
						Nenhum pedido encontrado
					</div>
				</div>
			</div>
			<div class="col-9 box_chat">
				<div class="col-12 box_header">
					<div class="d-flex pull-left" v-if="chat.solicitation">
						<div class="box_avatar_header pull-left" v-if="!contact.image" :style="{ 'background-image': 'url(\'/img/user.jpg\')'}"></div>
						<div class="box_avatar_header pull-left" v-if="contact.image" :style="{ 'background-image': 'url(\''+contact.image+'\')'}"></div>
						<div style="display: grid; align-items: center;">
							<div class="box_nome">{{contact.name}}</div>
							<div class="d-flex box-stars" v-if="contact.rate != null">
								<i
									class="fa"
									v-for="(item, index) in stars"
									:class="{'fa-star': item <= contact.rate, 'fa-star-o': item > contact.rate}"
									:key="index"
								></i>
							</div>
						</div>
					</div>
					<div class="d-flex box_button_simulator pull-right" v-if="chat.solicitation" @click="modalSimulator()">
				        <div class="box_icon">
				          <i class="fa fa-usd"></i>
				        </div>
				        <div class="box_text">
				          Simulador de Venda
				        </div>
				    </div>
				</div>
				<div class="col-12 box_interno_chat">
					<div v-if="loadingChat" class="d-flex justify-content-center box_loading" style="padding: 32vh 0;">
						<div class="spinner-grow text-secondary" style="width: 5rem; height: 5rem;" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
					<div class="col-12 box_aviso" v-if="!chat.solicitation && !loadingChat">
						<i class="fa fa-info-circle" aria-hidden="true"></i><br/>
						<span>Clique em um item do lado esquerdo para inicar uma interação.</span>
					</div>
					<div v-if="chat.solicitation" class="row justify-content-center box_header_top">
						<div class="col-4" data-toggle="modal" @click="modalDetailsSolicitation(chat.solicitation)">
							<div class="box_header_review">
								<span v-if="category.icon"><img class="box_header_review_image_info" :src="category.icon"></span>
								<span class="box_header_review_info_text"> {{chat.solicitation.vehicle}} / #{{chat.solicitation.id}}</span>
								<span style="margin-right: 5px;"> {{contact.name}}</span>
							</div>
						</div>
						<div class="btn-group dropleft btn_menu_acoes">
					    <div class="btn" id="dropdownMenuReference" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
					    	<i class="fa fa-ellipsis-v"></i>
					    </div>
					    <div class="dropdown-menu dropdown-menu-right">
					      <button class="dropdown-item" type="button" @click="modalFinalizarChat()" :disabled="chat.solicitation.finished == 1">Finalizar Chat</button>
					    </div>
					  </div>
					</div>
					<div v-if="chat.solicitation" class="row align-items-end box_conversa">
						<transition-group name="list" style="width:100%;">
							<div class="col-12 box_line_msg" v-for="(c, index) in chat.messages" :key="index+c.created_at">
								<div class="box_msg" :class="c.send_id == user.id ? 'msg1' : 'msg2'">
									<div class="box_avatar pull-left" v-if="!contact.image && c.send_id != user.id" :style="{ 'background-image': 'url(\'/img/user.jpg\')'}"></div>
									<div class="box_avatar pull-left" v-if="contact.image && c.send_id != user.id" :style="{ 'background-image': 'url(\''+contact.image+'\')'}" data-toggle="modal" data-target="#previewImageModal" @click="imagePreview = contact.image" style="cursor:pointer;"></div>

									<div class="box_text" :class="c.send_id == user.id ? 'cor1' : 'cor2'">
										<!-- <div class="box_interno_text" v-if="c.message" v-html="c.message"></div> -->
										<div class="box_interno_text" v-if="c.message" v-html="verifMessage(c.message)"></div>
										<div class="box_interno_text" style="text-align: center;" v-if="c.image">
											<img class="img_enviada" :src="c.image" data-toggle="modal" data-target="#previewImageModal" @click="imagePreview = c.image">
										</div>
										<div class="box_time">
											{{c.created_at}}
										</div>
									</div>
									<div class="box_avatar pull-right" v-if="!user.image && c.send_id == user.id" :style="{ 'background-image': 'url(\'/img/user.jpg\')'}"></div>
									<div class="box_avatar pull-right" v-if="user.image && c.send_id == user.id" :style="{ 'background-image': 'url(\''+user.image+'\')'}"></div>
								</div>
							</div>
						</transition-group>
						<div class="col-12 box_finish_chat" v-if="chat.solicitation.finished == 1">
							<div class="msg">Chat finalizado. Você não pode enviar mensagens.</div>
						</div>
					</div>
					<div v-if="chat.solicitation" class="col-12 box_footer">
						<button class="box_anexo" @click="clickSelectFiles()" :disabled="chat.solicitation.finished == 1">
							<i class="fa fa-paperclip" aria-hidden="true"></i>
							<input id="files-upload" name="file" type="file" accept="image/*" @change="selected_images(chat.solicitation, contact, user)" style="display: none;" multiple/>
						</button>
						<div class="box_message">
							<input type="search" autofocus v-model="message" id="input_message" class="form-control pull-left" placeholder="Digite uma mensagem e pressione ENTER para enviar" v-on:keyup.enter="enviar_mensagem(message, chat.solicitation, contact, user)" :disabled="chat.solicitation.finished == 1">
						</div>
						<button class="box_send d-flex justify-content-center align-items-center" @click="enviar_mensagem(message, chat.solicitation, contact, user)" :disabled="chat.solicitation.finished == 1">
							<img src="/img/send.png">
						</button>
					</div>
				</div>
			</div>
		</div>

		<!-- MODAL DETALHES DO PEDIDO -->
    <div class="modal fade" id="detailsSolicitationModal" tabindex="-1" role="dialog" aria-labelledby="detailsSolicitationModalLabel">
      <div class="modal-dialog modal-lg" role="document" style="max-width: 60%;">
        <div class="modal-content">
          <div class="modal-header d-flex" style="align-items:center;">
          	  <div class="col-5 padding_none">
	          	  <h4 class="modal-title" id="detailsSolicitationModal" v-if="detailsSolicitation">
	          	  Confira os detalhes da sua venda!</h4>  
	          	</div>
          	  <div class="col-6 d-flex box_info">
          	  	<div class="box_icon">
          	  		<img src="/img/button_pecas_color.png">
          	  	</div>
          	  	<div class="box_text">
	          	  	<span>Veículo: {{detailsSolicitation.vehicle}}</span></br>
	          	  	<span>Categoria: {{detailsSolicitation.category}}</span></br>
	          	  	Subcategorias:
	          	  	<span v-for="(sub, index) in detailsSolicitation.subcategories" :key="index+sub">
		                {{sub}}
		                <span v-if="index+1 < detailsSolicitation.subcategories.length">/</span>
		            </span></br>
	          	  	<span>Preferência:</span>
	          	  	<span v-if="detailsSolicitation.new">Novo</span>
		            <span v-if="detailsSolicitation.semi_new">Seminovo</span>
		            <span v-if="!detailsSolicitation.new && !detailsSolicitation.semi_new">Não especificado</span>
	          	  </div>
          	  </div>
          	  <div class="col-1 padding_none">
		          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="detailsSolicitation = ''">
		              <span aria-hidden="true">&times;</span>
		          </button>
	          </div>
          </div>
          <div class="modal-body row" v-if="detailsSolicitation">
          	<div class="col-12 padding_none" v-for="(k, index) in detailsSolicitation.keys" :key="index+k.key">
	          	<div class="col-7 row line_details">
		          	<div class="col-12 padding_none box_text">
		                Item: {{k.key}}
		            </div>
		            <div class="col-12 padding_none">
			          	<div class="box_image" v-for="(i, index2) in k.images" :key="index2+i" src="i" :style="{ 'background-image': 'url(\''+i+'\')'}" data-toggle="modal" data-target="#previewImageModal" @click="imagePreview = i"></div>
			        </div>
	            </div>
            </div>
          </div>
          <div class="modal-footer">
          	<div v-if="!loadingProposals && proposals.length > 0">
	          	<a :href="'seller-proposals?solicitation=' + detailsSolicitation.id + '&contact_id='+ contact.id + '&contact_cep='+ contact.cep">
				        <div class="d-flex box_button">
				        	<div class="box_icon">
			          	  		<i class="fa fa-usd"></i>
			          	  	</div>
				        	<div class="box_text">
			          	  		VER PROPOSTAS
			          	  	</div>
				        </div>
			    		</a>
			    	</div>
          	<div v-if="!loadingProposals && proposals.length == 0 && chat && chat.solicitation && chat.solicitation.finished == 0">
          		<a :href="'seller-proposals?solicitation=' + detailsSolicitation.id + '&contact_id='+ contact.id + '&contact_cep='+ contact.cep + '&new_solicitation=true'">
	          		<div class="d-flex box_button">
					        <div class="box_icon">
					          <i class="fa fa-usd"></i>
					        </div>
					        <div class="box_text">
					          CRIAR PROPOSTA
					        </div>
					      </div>
					    </a>
          	</div>
          	<div v-if="loadingProposals" class="d-flex justify-content-center">
							<div class="spinner-grow text-secondary" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</div>
	      	</div>
        </div>
      </div>
    </div>

    <!-- MODAL SIMULADOR -->
    <div class="modal fade" id="simulatorModal" tabindex="-1" role="dialog" aria-labelledby="simulatorModalModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
          	<h4 class="modal-title">Simulador de Custos</h4>
          	<button type="button" class="close" data-dismiss="modal" aria-label="Close">
            	<span aria-hidden="true">&times;</span>
       		</button>
          </div>
          <div class="modal-body" style="padding: 0;">
			<simulator
				:authorization="authorization"
		    	:user="user"
		    	:modal="true">
		  	</simulator>
          </div>
        </div>
      </div>
    </div>

		<!-- MODAL VIZUALIZAÇÃO DE IMAGEM -->
    <div class="modal fade" id="previewImageModal" tabindex="-1" role="dialog" aria-labelledby="previewImageModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
	          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="imagePreview = ''">
	              <span aria-hidden="true">&times;</span>
	          </button>
          </div>
          <div class="modal-body text-center" v-if="imagePreview">
			<img class="image_preview" :src="imagePreview">
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>

		<!-- MODAL FINALIZAR CHAT -->
    <div class="modal fade" id="finalizarChatModal" tabindex="-1" role="dialog" aria-labelledby="finalizarChatModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
          	<h4 class="modal-title">Finalizar Chat</h4>
          	<button type="button" class="close" data-dismiss="modal" aria-label="Close">
            	<span aria-hidden="true">&times;</span>
       			</button>
          </div>
          <div class="modal-body">
          	<div
              class="box-text">
              Selecione uma resposta rápida:
            </div>
          	<div class="form-check" v-for="(item, index) in responsesDefault" :key="index">
						  <input class="form-check-input" v-model="response" type="radio" name="responses" :id="item.text" :value="item.text">
						  <label class="form-check-label" :for="item.text">
						    {{item.text}}
						  </label>
						</div>
						<textarea
              class="form-control"
              placeholder="Digite aqui..."
              v-if="response == 'Personalizada'"
              v-model="responseCustom"
            ></textarea>
          </div>
          <div class="modal-footer d-flex">
            <button v-if="response != 'Personalizada'" class="btn" @click="finalizarChat()" :disabled="loadingFinalizar || !response">
              <span v-if="!loadingFinalizar">Finalizar</span>
              <div v-if="loadingFinalizar" class="spinner-grow spinner-grow-sm" role="status"></div>
            </button>
            <button v-if="response == 'Personalizada'" class="btn" @click="finalizarChat()" :disabled="loadingFinalizar || !responseCustom">
              <span v-if="!loadingFinalizar">Finalizar</span>
              <div v-if="loadingFinalizar" class="spinner-grow spinner-grow-sm" role="status"></div>
            </button>
          </div>
        </div>
      </div>
    </div>

	</div>
</template>

<script>
	import axios from 'axios';
	import moment from 'moment';
	import io from 'socket.io-client';

	export default {
		props: ['user', 'authorization', 'solicitation_id'],
		data: function() {
			var tunnel = 'https://tunnel.d3t.com.br';
			var api = 'https://autoradar.d3t.com.br/api';

			if (window.location.hostname == 'localhost'){
				tunnel = 'http://172.20.0.1:5555';
				api = 'http://172.24.0.1/api';
			}

			return {
				loadingSolicitations: true,
				loadingProposals: true,
				loadingChat: false,
				loadingFinalizar: false,
	      stars: [1,2,3,4,5],
				imagePreview: '',
				detailsSolicitation: '',
				search: '',
				solicitations: [],
				proposals: [],
				contact: '',
				chat: [],
				message: '',
				file: '',
				selectedFiles: [],
				socket: io(tunnel, {
					query: `domain=${api}&authorization=${this.user.app_token}`
				}),
				response: '',
				responseCustom: '',
				responsesDefault: [],
			};
		},

		created() {
      this.get_solicitations();
      this.timeoutSolicitations();
      this.socket.on('observer', async (data) => {
				console.log(data, 'observer')
				const content = data.content.content;
				if (this.chat){
					if (this.chat.solicitation.id == content.solicitation_id){
						this.chat.messages.push(content);
						this.scrollChat(100);
						this.setViewedSolicitation(content.solicitation_id);

						if (content.finished){

							if (this.chat.solicitation){
								this.chat.solicitation.finished = 1;
								var index = this.solicitations.map((el) => el.id).indexOf(content.solicitation_id);
								this.solicitations[index].finished = 1;
							}
						}
					} else {
						var index = this.contacts.map((el) => el.solicitation.id).indexOf(content.solicitation_id);
						this.contacts[index].new_messages++;
					}
				} else {
					var index = this.solicitations.map((el) => el.solicitation.id).indexOf(content.solicitation_id);
					this.solicitations[index].contact.new_messages++;
				}
			});
    },

		mounted() {

	  },

		computed: {
			filteredSolicitations() {
	      return this.solicitations.filter(item => {
	        return item.contact.name.toLowerCase().includes(this.search.toLowerCase())
	      })
	    }
		},

		methods: {
			modalSimulator(){
        $('#simulatorModal').modal({
          show: true,
        })
      },

			modalFinalizarChat(){
				this.response = '';
				this.responseCustom = '';
        $('#finalizarChatModal').modal({
          show: true,
        })
      },

			modalDetailsSolicitation(solicitation){
				this.get_proposals(solicitation.id);
				this.detailsSolicitation = solicitation;
        $('#detailsSolicitationModal').modal({
          show: true,
        })
      },

			finalizarChat(){
				this.loadingFinalizar = true;
				var msg = '';
				if (this.response == 'Personalizada'){
					msg = this.responseCustom;
				} else {
					msg = this.response;
				}
				this.enviar_mensagem("¨"+msg, this.chat.solicitation, this.contact, this.user, 1);

				axios.post('/api/chat/finish/' + this.chat.solicitation.id_provider, this.chat.solicitation, { headers: { Authorization: this.authorization }})
        .then(response => {
          console.log("finalizarChat");
          console.log(response);
          this.$swal({
            title: "Sucesso!",
            text: "Chat finalizado com sucesso.",
            imageUrl: "/img/sucesso.png",
            imageWidth: 200,
          });
          this.response = '';
					this.responseCustom = '';
					this.chat.solicitation.finished = 1;
					var index = this.solicitations.map((el) => el.id).indexOf(this.chat.solicitation.id);
					this.solicitations[index].finished = 1;
          this.loadingFinalizar = false;
					$('#finalizarChatModal').modal('hide');
        })
        .catch(e => {
          this.loadingFinalizar = false;
          var msg = 'Ocorreu um erro ao finalizar chat.'
          this.$swal("Oops!", msg, "error")
          console.error(e)
        })
      },

			scrollChat (time) {
				setTimeout(function() {
					$(".box_conversa").scrollTop($(".box_conversa").prop("scrollHeight"));
				}, time);
			},

			verifLineActive (data) {
				if (this.contact){
					/*if (data.id == this.contact.id){*/
					if (data.id == this.chat.solicitation.id){
						return true;
					}
				}
				return false;
			},

			clickSelectFiles () {
        $('#files-upload').val('');
        $('#files-upload').trigger('click');
    	},

			get_solicitations () {
				this.loadingSolicitations = true
				this.solicitations = [];

				let url = '/api/solicitations/provider';
				
				// For clients
				if(this.user.type == 1) {
					url = '/api/chat/solicitations'
				}

				axios.get(url, { headers: { Authorization: this.authorization } })
				.then(response => {
					console.log('get_solicitations');
					console.log(response.data.content);
					this.solicitations = response.data.content;

					if (this.solicitation_id){
						this.solicitations.forEach((value, index) => {
		        	if (value.id == this.solicitation_id){
		        		this.init_chat(value);
		        	}
		        });
					}
				})
				.catch(e => {
					var msg = 'Houve um erro ao buscar as solicitações! Por favor, tente novamente.'
					this.$swal("Oops!", msg, "error")
					console.error(e)
				})
				.finally(() => {
					this.loadingSolicitations = false
				})
			},

			updateSolicitations () {
				let url = '/api/solicitations/provider';
				// For clients
				if(this.user.type == 1) {
					url = '/api/chat/solicitations'
				}
				axios.get(url, { headers: { Authorization: this.authorization } })
				.then(response => {
					this.solicitations = response.data.content;
					this.timeoutSolicitations();
				})
				.catch(e => {
					console.error(e)
				})
			},

			timeoutSolicitations () {
				var self = this;
				setTimeout(function() {
					self.updateSolicitations();
				}, 60000);
			},

			get_proposals (id) {
				this.loadingProposals = true
				this.proposals = [];

				let url = '/api/solicitations/provider';

				axios.get('/api/proposal/'+id, { headers: { Authorization: this.authorization } })
				.then(response => {
					console.log('get_proposals');
					console.log(response.data.content);
					this.proposals = response.data.content;
				})
				.catch(e => {
					var msg = 'Houve um erro ao buscar as propostas! Por favor, tente novamente.'
					this.$swal("Oops!", msg, "error")
					console.error(e)
				})
				.finally(() => {
					this.loadingProposals = false
				})
			},

			setViewedSolicitation (id) {
				console.log("setViewedSolicitation");
				console.log(id);
				axios.get(`/api/chat/messages/solicitation/${id}/viewed`,
						{ headers: { Authorization: this.authorization } })
				.then(response => {
					console.log(response.data);
				})
				.catch(e => {
					console.error(e)
				})
				.finally(() => {})
			},

			init_chat (data) {
				this.loadingChat = true
				this.chat = [];
				this.contact = '';
				this.category = {};

				$(".line").removeClass('line_active');
				$("#"+data.id).addClass('line_active');
				this.setViewedSolicitation(data.id);

				axios.get(`/api/chat/messages/${data.contact.id}/solicitation/${data.id}`,
						{ headers: { Authorization: this.authorization } })
				.then(response => {
					console.log('init_chat');
					console.log(response.data.content);

					this.chat = response.data.content;
					this.category = data.category;
					this.contact = data.contact;
					console.log("this.chat");
					console.log(this.chat);

					var index = this.solicitations.map((el) => el.id).indexOf(data.id);
					this.solicitations[index].contact.new_messages = '';
					this.solicitations[index].new = false;
					$("#input_message").focus();
					this.get_phrases_chat(this.user.id, data.id);
				})
				.catch(e => {
					var msg = 'Houve um erro ao iniciar o chat! Por favor, tente novamente.'
					this.$swal("Oops!", msg, "error")
					console.error(e)
				})
				.finally(() => {
					this.loadingChat = false;
					this.scrollChat(100);
				})
			},

			enviar_mensagem (message, solicitation, contact, user, finished = 0) {
				const data = {
					method: 'POST',
					endpoint: '/chat/new_message',
					
					content: {
						solicitation_id: solicitation.id,
						send_id: user.id,
						receive_id: contact.id,
						image: null,
						message: message,
						created_at: moment().format('YYYY-MM-DD HH:mm'),
						finished: finished,
					}
				}
				
				this.chat.messages.push(data.content);
				this.socket.emit('event', data);

				this.message = '';
				this.scrollChat(100);
			},

			getExtension(path) {
			    var basename = path.split(/[\\/]/).pop(),
			        pos = basename.lastIndexOf(".");

			    if (basename === "" || pos < 1)
			        return "";

			    return basename.slice(pos + 1);
			},

			async send_files (message, files) {

				let formData = new FormData();
                Object.keys(message).map((key) => {
			      if(key === 'image') {
			      	for (let i = 0; i < files.length; i++) {
	                    let file = files[i];
	                    formData.append(`image_${i}`,file, `${i}_${new Date().getTime()}.${this.getExtension(files[i].name)}`);
	                }
			      } else {
			        if(message[key]) {
			          formData.append(key, message[key])
			        }
			      }
			    });

				const { data } = await axios.post(`/api/chat/new_image_message`, formData, { headers: { Authorization: this.authorization }})
				.catch(e => {
					var msg = 'Houve um erro ao enviar imagens! Por favor, tente novamente.'
					this.$swal("Oops!", msg, "error")
					console.error(e)
				});
				const { content } = data;

			    return {
			      success: true,
			      content: content
			    };
			},

			async selected_images (solicitation, contact, user) {
				if (!event.target.files.length){
					return false;
				}

				var message = {
					solicitation_id: solicitation.id,
					send_id: user.id,
					receive_id: contact.id,
					image: null,
					message: null,
					created_at: moment().format('DD/MM/YYYY [às] HH:mm'),
				}

				const files = event.target.files;
				for (let i = 0; i < files.length; i++) {
					let file = files[i];
					message.image = URL.createObjectURL(file);
                    this.chat.messages.push(Vue.util.extend({}, message));                    
                    this.scrollChat(100);
                }

                let { success, content } = await this.send_files(message, files);

                if (!success) {
			    	return Alert.alert('Oops!', content);
			    }

			    let send = [];
			    content.map((a) => {
			    	if (a.image.indexOf('storage/') != -1) {
			        	const split = a.image.split('storage/');
			        	a.image = `storage/${split[1]}`;
			    	}
			    	send.push({
			    		"method": 'POST',
			    		"endpoint": '/chat/new_message',
			    		"content": a,
		    		})
			    });

			    for (let i = 0; i < send.length; i++) {
					this.socket.emit('event', send[i]);
                }

				this.selectedFiles = '';
				$('#files-upload').val('');
				this.scrollChat(100);
			},

			get_phrases_chat (id, solicitation) {				
				this.responsesDefault = [];

				axios.get('/api/phrase-chats/'+id+'/solicitation/'+solicitation, { headers: { Authorization: this.authorization } })
				.then(response => {
					console.log('get_phrases_chat');
					console.log(response.data.content);
					this.responsesDefault = response.data.content;
					this.responsesDefault.push({'text': 'Personalizada'});
				})
				.catch(e => {
					var msg = 'Houve um erro ao buscar as frases do chat! Por favor, tente novamente.'
					this.$swal("Oops!", msg, "error")
					console.error(e)
				})
				.finally(() => {
					
				})
			},

			verifMessage (message){
				if (message.substring(0,1) == "¨"){
					return "<span class='font-weight-bold'>"+message.replace("¨", "")+"</span>";
				} else {
					return message;
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.chat_solicitation {
		width: 100%;
		background-color: #fff;
		padding: 0;

		.padding_none {
		  padding-left: 0 !important;
		  padding-right: 0 !important;
		}

		.btn_menu_acoes{
			position: absolute;
			right: 15px;
			top: 22px;
			cursor:pointer;

			i{
				font-size:25px;
			}
		}

		#finalizarChatModal{

			.modal-header{
	      background-color: #333;
	      color: #fff;

	      .close{
	        color: #fff;
	      }
	    }

			.modal-body{
				.box-text{
					font-size: 18px;
					margin-bottom: 20px;
				}

				.form-check{
					font-size: 18px;
					padding-bottom: 15px;
					padding-left: 35px;
				}

				.form-control{
	        border-radius: 20px;
	        color: #000;
	        padding: 10px;
	        font-size: 15px;
	        border: 1px solid #ced4da;
	        margin-top: 10px;
	      }

	      textarea{
	        resize: none;
	        height: 120px;
	      }
      }

      .modal-footer{
	      justify-content: center;

	      .btn {
	        border-radius: 20px;
	        text-align:center;
	        border: 1px solid #01fe03;
	        background-color: #01fe03;
	        color: #000;
	        width: 200px;
	      }
	    }
		}

		.box_button_simulator{
	      padding: 0;
	      margin: 0;
	      align-items: center;
	      cursor:pointer;
	      position: relative;

	      .box_icon{
	        float: left;
	        width: 40px;
	        height: 40px;
	        margin-right: 10px;
	        line-height: 40px;
	        font-size: 28px;
	        border-radius: 100%;
	        background-color: #ff8533;
	        color: #fff;
	        text-align:center;
	        position: absolute;
    		left: -33px;
	      }
	      .box_text{
	        float: left;
	        font-size: 14px;
	        line-height: 1.2;
	        background-color: #fff;
	        padding: 8px;
	        padding-left: 12px;
	        border-bottom-right-radius: 30px;
    		border-top-right-radius: 30px;

	        span {
	          line-height: 1.2;
	        }
	      }
	    }

		#detailsSolicitationModal{
			.modal-header{
				background-color: #333;
				color: #fff;

				.close{
					color: #fff;
				}

				.box_info{
					padding: 0;
					align-items: center;

					.box_icon{
						float: left;

						img{
							width: 40px;
							margin-right: 10px;
						}
					}
					.box_text{
						float: left;
						font-size: 14px;
						line-height: 1.2;

						span {
							line-height: 1.2;
						}
					}
				}
			}

			.modal-footer{
				background-color: #333;
				color: #fff;

				a{
					color: #fff;
				}

				.box_button{
					padding: 0;
					align-items: center;
					cursor:pointer;

					.box_icon{
						float: left;
						width: 40px;
						height: 40px;
						margin-right: 10px;
						line-height: 40px;
						font-size: 28px;
						border-radius: 100%;
						background-color: #ff8533;
						color: #fff;
						text-align:center;
					}
					.box_text{
						float: left;
						font-size: 14px;
						line-height: 1.2;

						span {
							line-height: 1.2;
						}
					}
				}
			}

			.modal-body{
				padding: 10px;

				.line_details{
					padding: 10px 0;
					border-bottom: 1px solid #333;
					margin-bottom: 5px;

					.box_text{
						color: #000;
						font-size: 13px;
						margin-bottom: 5px;
					}

					.box_image{
						cursor: pointer;
						width: 40px;
						height: 40px;
						border-radius: 100%;
						border: 1px solid #333;
						background-color: #fff;
						margin: 0 5px;
						box-shadow: 1px 1px 3px #ccc;
						float:left;
						background-position: center;
                		background-repeat: no-repeat;
                		background-size: cover;
					}
				}
			}
		}

		.box-stars{
			i{
				/*cursor: pointer;*/
				padding: 0 2px;
			}

			.fa-star{
				color: #FF8533;
			}
		}

		.form-control{
		    border-radius: 20px;
		    border: 0;
		    padding: 15px;
		    font-size: 15px;
		}

		.row{
			margin: 0;
		}

		.box_loading{
			padding: 50px 0;
		}

		#previewImageModal{
			
			.modal-header{
				border: 0;
			}

			.modal-footer{
				border: 0;
				height: 56px;
			}

			.image_preview{
				max-width: 100%;
			}
		}
		
		.box_lista{
			padding: 0;
			min-height: 79vh;
			max-height: 79vh;

			.box_header{
    			display: flex;
				align-items: center;
				background-color: #e7e7e7;
				border-left: 1px solid #e0e0e0;
				padding: 0 15px;
				line-height: 55px;
				min-height: 55px;
				max-height: 55px;

				&_text {
					width: 100%;
					background-color: #fff;
					border-top-right-radius: 15px;
        			border-bottom-right-radius: 15px;
					padding: 5px 20px;
    				margin-left: -15px;
				}
			}

			.box_search{
				background-color: #f7f7f7;
				border-left: 1px solid #e0e0e0;
				border-right: 1px solid #e0e0e0;
				padding: 7px 15px;
				line-height: 55px;
				min-height: 55px;
				max-height: 55px;
			}

			.box_lines{
				border-right: 1px solid #e0e0e0;
				min-height: 69vh;
				max-height: 69vh;
				padding: 0;
				line-height: 0;
				overflow: auto;

				.box_sem_registro{
				    text-align: center;
    				padding: 12px;
    				color: #ccc;
				}

				.line{
					padding: 15px 10px;
					border-bottom: 1px solid #eee;
					display: inline-block;
					overflow:auto;
					cursor: pointer;

					.box_avatar{
						width: 40px;
						height: 40px;
						border-radius: 100%;
						background-color: #ccc;
						margin: 0 5px;
						box-shadow: 1px 1px 3px #ccc;
						float:left;
						margin-right: 10px;
						background-position: center;
                		background-repeat: no-repeat;
                		background-size: cover;
					}

					.box_info{
						float:left;

						.box_nome{
							font-weight: 600;
							float:left;
							width:100%;
						}

						.box_details{
							float:left;
							font-size: 14px;
							width:100%;
						}
					}

					.box_new_messages{
						float:right;
						width: 20px;
						height: 20px;
						background-color: #c56e0d;
						border-radius: 100%;
						text-align:center;
						line-height: 20px;
						padding: 0;
						color:#fff;
						font-size: 14px;
						box-shadow: 1px 1px 3px #ccc;
					}
				}

				.line_active{
					background-color: #ebebeb;
				}

				.new_solicitation{
		    		animation: pulse 1s infinite;
		    		z-index: 1;
		    		border: 1px solid rgba(40, 167, 69, 0.4);
				}
			}
		}
		
		.box_chat{
			padding: 0;
			min-height: 79vh;
			max-height: 79vh;

			.box_avatar_header {
				width: 40px;
				height: 40px;
				border-radius: 100%;
				background-color: #ccc;
				margin: 0 15px 0 0;
				box-shadow: 1px 1px 3px #ccc;
				background-position: center;
        		background-repeat: no-repeat;
        		background-size: cover;
			}

			.box_header{
				background-color: #e7e7e7;
				border-bottom: 1px solid #e0e0e0;
				padding: 7px 15px;
				min-height: 55px;
				max-height: 55px;
				display:flex;
				justify-content: space-between;

				.box_nome{
					font-weight: 600;
				}

				.box_info{
					font-size: 11px;
				}
			}

			.box_footer{
				background-color: #e7e7e7;
				border-top: 1px solid #e0e0e0;
				padding: 7px 15px;
				line-height: 55px;
				min-height: 55px;
				position:absolute;
				bottom:0;
				display: flex;

				.box_anexo{
					float:left;
					font-size: 22px;
					line-height: 36px;
					margin-right: 15px;
					cursor: pointer;
					background: none;
    			border: 0;
    			color: #7e7e7e;
    			padding: 0;
				}

				.box_message{
					float:left;
					width: 100%;
				}

				.box_send{
					line-height: 32px;
					margin: 0 10px;
					cursor: pointer;
			    background: none;
    			border: 0;

					img{
						width: 20px;
    					vertical-align: middle;
					}
				}
			}

			.box_interno_chat{
				min-height: 72vh;
				max-height: 72vh;
				background-color: rgba(236, 240, 245, 0.93);
				padding: 0;
				padding-bottom: 55px;
				background-image: url('/img/bg_chat.png');
			    background-size: contain;
			    background-position: center;

				.box_header_top{
					position: absolute;
					width: 100%;
					z-index: 1;
				    padding-bottom: 10px;
					border-bottom: 1px solid #ccc;
					background-color: #fff;
				}

				.box_header_review{
					margin-top: 30px;
					background-color: #333333;
					border-radius: 35px;
					padding: 10px 15px;
					font-size: 14px;
					text-align: center;
					cursor: pointer;
					color: #fff;

					&_image_info {
						position: absolute;
						top: 6px;
						left: 5px;
						width: 45px;
						height: 45px;
						z-index: 1;
					}

					&_info_text {
						position: absolute;
						top: 20px;
						left: 30px;
						min-width: 100px;
						background-color: #e7e7e7;
						border-radius: 15px;
						color: #333333;
						font-size: 13px;
						font-weight: bold;
						text-transform: uppercase;
						text-align: left;
						padding: 0px 35px;
					}
				}

				.box_aviso{
					text-align:center;
					padding: 32vh 0;

					i{
						font-size: 50px;
					}
					span{
						font-size: 13px;
						margin-top: 15px;
					}
				}

				.box_conversa{
					overflow:auto;
					padding: 15px;
					padding-top: 100px;
					max-height: 65vh;

					.box_finish_chat{
						display: flex;
						align-items: center;
						justify-content: center;

						.msg{
							box-shadow: 1px 1px 3px #ccc;
							border-radius: 30px;
							font-size: 14px;
							width: 400px;
							text-align: center;
							padding: 10px;
							background-color: #ccc;
							color: #333333;
						}
					}

					.box_line_msg{
						padding: 5px 20px;
						display: inline-block;

						.box_msg{
							width: auto;

							.box_avatar {
								width: 35px;
								height: 35px;
								border-radius: 100%;
								background-color: #ccc;
								margin: 0 10px;
								box-shadow: 1px 1px 3px #ccc;
								background-position: center;
		        		background-repeat: no-repeat;
		        		background-size: cover;
							}

							.box_text{
								width: auto;
								float: left;
								border-radius: 30px;
								line-height: 1.3;
								padding: 0;
								box-shadow: 1px 1px 3px #ccc;
								font-size: 14px;
								position:relative;
								min-width: 200px;

								.box_interno_text{
									float: left;
									padding: 10px;
									width: 100%;
									padding-bottom: 3px;
									color: #333333;
								}

								.img_enviada{
									max-width: 250px;
									cursor: pointer;
								}

								.box_time{
									float: left;
									font-size: 10px;
									color: #333333;
									text-align: right;
									width: 100%;
									padding-right: 10px;
								}
							}

							.cor1{
								background-color: #fff;
								border-top-right-radius: 0;
								padding-right: 15px;
							}

							.cor2{
								background-color: #FF8533;
								border-top-left-radius: 0;
								padding-right: 15px;
							}
						}

						.msg1{
							float: right;
						}

						.msg2{
							float: left;
						}
					}
				}
			}
		}

	    @-webkit-keyframes pulse {
		  0% {
		    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4);
		  }
		  70% {
		      -webkit-box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
		  }
		  100% {
		      -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
		  }
		}

		@keyframes pulse {
		  0% {
		    -moz-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4);
		    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4);
		  }
		  70% {
		      -moz-box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
		      box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
		  }
		  100% {
		      -moz-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
		      box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
		  }
		}

		.list-enter-active, .list-leave-active {
		  transition: all 0.5s;
		}
		.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
		  opacity: 0;
		  transform: translateY(20px);
		}
	}
</style>