/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Popper = require('popper.js');

window.$ = window.jQuery = require('jquery');

require('admin-lte');
require("bootstrap");

window.Vue = require("vue");

import Vue from "vue";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import money from "v-money";
Vue.use(money, { precision: 4 });

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.component("content-header", require("./cms/ContentHeader.vue").default);
Vue.component("data-table", require("./cms/DataTable.vue").default);
Vue.component("data-table-proposals", require("./cms/DataTableProposals.vue").default);
Vue.component("data-table-sellers", require("./cms/DataTableSellers.vue").default);
Vue.component("tabs", require("./cms/Tabs.vue").default);
Vue.component("ui-form", require("./cms/UIForm.vue").default);
Vue.component("ui-select", require("./cms/UISelect.vue").default);
Vue.component("ui-textarea", require("./cms/UITextarea.vue").default);
Vue.component("ui-money", require("./cms/UIMoney.vue").default);
Vue.component("ui-phone", require("./cms/UIPhone.vue").default);
Vue.component("ui-mask-input", require("./cms/UIMaskInput.vue").default);
Vue.component("alert", require("./cms/Alert.vue").default);
Vue.component("checkboxes", require("./cms/Checkboxes.vue").default);
Vue.component("radios", require("./cms/Radios.vue").default);
Vue.component("dropdown-list", require("./cms/DropdownList.vue").default);
Vue.component("dropdown-events", require("./cms/DropdownEvents.vue").default);
Vue.component("cidade-bairro", require("./cms/Cidade-bairro.vue").default);
Vue.component("the-gallery", require("./front/TheGallery.vue").default);
Vue.component("subcategory-generator", require("./cms/SubcategoryGeneretor.vue").default);
Vue.component("client-ocupation", require("./cms/ClientOcupation.vue").default);
Vue.component("chat-solicitation", require("./cms/ChatSolicitation.vue").default);
Vue.component("simulator", require("./cms/Simulator.vue").default);
Vue.component("list-proposals", require("./cms/ListProposals.vue").default);
Vue.component("avaliation", require("./cms/Avaliation.vue").default);
Vue.component("publicity-matches", require("./cms/PublicityMatches.vue").default);

const app = new Vue({
  el: "#app"
});
