<template>
  <div class="box box-widget">
    <div class="box-header">
      <div class="row">
        <div class="col-md-3 col-sm-6 d-flex align-items-center">
          <h3 class="box-title">{{title}}</h3>
        </div>
        <div class="col-9 box_filtros my-3">
          <form ref="form" method="GET" class="form-horizontal" :action="url">
          <div class="row">
            <div class="col-7">
            </div>
            <div class="col-5">
              <div class="col-md-12 col-sm-12" style="padding: 0;">
                <div class="input-group">
                  <input
                    type="text"
                    name="busca"
                    class="form-control"
                    placeholder="Buscar por"
                    :value="busca"
                  />
                  <span class="input-group-btn">
                    <!-- <button type="submit" class="btn btn-primary btn-flat">
                      <i class="fa fa-search"></i>
                    </button> -->
                    <a
                      :href="url"
                      class="btn btn-default btn-flat"
                      data-toggle="tooltip"
                      title="Limpar Busca"
                      style="color:#7e7e7e;"
                    >
                      <i class="fa fa-times"></i>
                    </a>
                    <button type="submit" class="btn btn-primary btn-flat"><i class="fa fa-search" aria-hidden="true"></i></button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
    <div class="box-body">
      <slot></slot>

      <form
        v-if="items.data.length > 0"
        :class="formClass"
        v-on:submit.prevent="confirmDelete"
        :action="url + '/destroy'"
        method="POST"
        >
        <input type="hidden" name="_token" :value="token" />
        <input type="hidden" name="_method" value="DELETE" />
        <table id="example1" class="table table-bordered table-striped">
          <thead>
              <th v-for="(title, index) in titles" :key="index">{{title}}</th>
              <th>Ações</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items.data" :key="index">
              <td v-for="(field, index) in item" :key="index" class="v-middle">
                <span v-if="field === null">{{field}}</span>
                <span v-else-if="!field.type && !field.payment">{{field}}</span>
                <span v-else-if="field.payment">{{checkDatePayment(field.installments)}}</span>                

                <span v-else-if="field.type == 'img'">
                  <img :src="field.src" style="max-width: 150px; max-height: 50px;" />
                </span>

                <span
                  v-else-if="field.type == 'badge'"
                  class="badge"                  
                  :class="'badge-' + field.status"
                >{{ field.text }}</span>

                <span
                  v-else-if="field.type == 'action'"
                  v-for="(action, index) in item.actions"
                  :key="index"
                >
                  <a
                    data-toggle="tooltip"
                    class="btn btn-flat ml-10"
                    :class="'btn-' + action.color"
                    :href="action.path"
                    :title="action.label"
                  >
                    <i :class="action.icon"></i>
                  </a>&nbsp;
                </span>
              </td>
              <td class="v-middle">
                <a
                  class="btn btn-flat btn-primary"
                  data-toggle="tooltip"
                  title="Detalhes"
                  @click="detailsSeller(item)"
                  style="cursor: pointer;"
                >
                  <i class="fa fa-eye" style="color:#fff;"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="(titles.length + 2)" align="left" vertical-align="center">
                <slot name="pagination"></slot>
              </td>
            </tr>
          </tfoot>
        </table>
      </form>

      <div v-if="items.data.length == 0" class="callout callout-default">
        <h4>Nenhum registro encontrado!</h4>
      </div>
    </div>



    <!-- MODAL DETALHES -->
    <div class="modal fade" id="detailsSellerModal" tabindex="-1" role="dialog" aria-labelledby="detailsSellerModalLabel">
      <div class="modal-dialog modal-lg" role="document" style="max-width: 50%;">
        <div class="modal-content">
          <div class="modal-header d-flex" style="align-items:center;padding: 16px;">
            <div class="col-5 p-0">
              <h4 class="modal-title" id="detailsSellerModal" v-if="seller">
              {{seller.name}}</h4>  
            </div>
            <div class="col-1 box_close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="modal-body row" style="padding: 10px;">
            <div v-if="loadingCredits" class="d-flex justify-content-center box_loading" style="width: 100%; padding: 50px 0;">
              <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="col-12 p-0 py-5 text-center" v-if="seller && !loadingCredits">
              <div class="text-center" v-if="seller.credits">
                <span>Saldo atual: <span style="font-size: 35px;">R$ {{seller.credits}}</span></span>
                <div class="mt-3 d-flex justify-content-center">
                  <div class="pull-left" style="margin-right: 10px;">
                    <money class="form-control input background" placeholder="Valor (R$)" v-model="credits" v-bind="moneyFormat"></money>
                  </div>
                  <button
                    class="btn btn-flat btn-primary"
                    @click="insertCredits()"
                    style="cursor: pointer;font-size: 14px;width:115px;"
                    :disabled="credits == 0 || loadingInsertCredits"
                  >
                    <span v-if="!loadingInsertCredits">Inserir créditos</span>
                    <div v-if="loadingInsertCredits" class="spinner-grow spinner-grow-sm" role="status"></div>
                  </button>
                </div>
                <div class="mt-3" style="" v-if="seller.insertCredits && !loadingInsertCredits">
                  <a :href="seller.insertCredits.redirect" target="_blank" style="">Clique aqui para pagar</a>
                </div>
              </div>
              <div class="text-center" v-if="!seller.credits">
                <div><i class="fa fa-warning text-warning mb-2" style="font-size: 40px;" aria-hidden="true"></i></div>
                <div class="text-warning">O usuário não possui conta no Melhor Envio!</div>
                <!-- <div class="mt-4 d-flex justify-content-center">
                  <button
                    class="btn btn-flat btn-primary"
                    style="cursor: pointer;font-size: 14px;width:115px;"
                    :disabled="loadingInsertCredits"
                  >
                    <span v-if="!loadingInsertCredits">Criar conta</span>
                    <div v-if="loadingInsertCredits" class="spinner-grow spinner-grow-sm" role="status"></div>
                  </button>
                </div> -->
              </div>
              <div v-if="loadingProposals" class="d-flex justify-content-center box_loading" style="width: 100%; padding: 50px 0;">
                <div class="spinner-grow text-secondary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="col-12 mt-5" v-if="!loadingProposals">
                <table id="example1" class="table table-bordered table-striped" v-if="seller.proposals.length > 0">
                  <thead>
                      <th>#</th>
                      <th><span class="font-weight-bold">Valor do frete</span></th>
                      <th>Criado em</th>
                      <th>Status</th>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in seller.proposals" :key="index">
                      <td class="v-middle">
                        {{item.id}}
                      </td>
                      <td class="v-middle">
                        <span class="font-weight-bold">R$ {{item.me_shipment_value}}</span>
                      </td>
                      <td class="v-middle">
                        {{item.created_at}}
                      </td>
                      <td class="v-middle">
                        <span
                          v-if="item.status.type == 'badge'"
                          class="badge"                  
                          :class="'badge-' + item.status.status"
                        >{{ item.status.text }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="" v-if="seller.proposals.length == 0">Nenhuma proposta cadastrada</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios';
  import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
  import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

  export default {
    props: [
      "title",
      "titles",
      "items",
      "items_complete",
      'authorization',
      "busca",
      "status",
      "url",
      "token",
      "formClass",
      "actions",
      "selected_init",
      "selected_finish",
    ],
    data: function() {
      return {
        deleteItems: [],
        proposal: '',
        loadingCredits: false,
        loadingInsertCredits: false,
        loadingProposals: false,
        btnDelete: {
          icon: "fa fa-trash",
          text: "Excluir registros selecionados",
          disabled: false
        },
        arrayStatus: [
          {value: 'todas', label: 'Todas'},
          {value: 'pendente', label: 'Pendente'},
          {value: 'recusada', label: 'Recusada'},
          {value: 1, label: 'Aguardando Pagamento'},
          {value: 2, label: 'Aguardando Envio'},
          {value: 3, label: 'Aguardando Entrega'},
          {value: 4, label: 'Concluído'},
          {value: 5, label: 'Pagamento Recebido'},
          {value: 6, label: 'Pagamento Parcial'},
        ],
        data_entrada: null,
        labels:[
          { key: 'thisDay', label: 'Hoje', value: 'day' },
          { key: 'lastDay', label: 'Ontem', value: '-day' },
          { key: 'last15Days', label: 'Últ. 15 dias', value: 15 },
          { key: 'thisMonth', label: 'Este mês', value: 'month' },
          { key: 'lastMonth', label: 'Mês passado', value: '-month' }
        ],
        attachment: '',
        imagePreview: '',
        seller: {
          "credits": [],
          "insertCredits": [],
          "proposals": [],
        },
        credits: '',
        moneyFormat: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          precision: 2,
          masked: false
        },
      };
    },
    created() {
      if (!this.items.data) {
        this.items.data = this.items;
      }
      if(this.selected_init != 'null'){
        this.data_entrada = { start: this.selected_init, end: this.selected_finish };
        console.log(this.data_entrada);
      }
    },
    computed: {
        total (toFloat = false) {
          if (this.proposal){
            let result = 0;
            let { value, delivery } = this.proposal;

            if(value) {
              result = this.moneyToFloat(value);
            }

            if(delivery.length && delivery[this.deliveryChoices] && delivery[this.deliveryChoices].value) {
              result += this.moneyToFloat(delivery[this.deliveryChoices].value);
            }

            /*if(toFloat) {
              return result;
            }*/

            return this.floatToMoney(result);
          }
        },
        computedInitDate() {
          return this.data_entrada.start;
        },
        computedFinishDate() {
          return this.data_entrada.end;
        },
      },
    methods: {
      
      detailsSeller: function(item) {
        // this.seller = [];
        this.seller = {
          "credits": [],
          "insertCredits": [],
          "proposals": [],
        },
        this.credits = '';
        this.seller = Object.assign({}, item);
        if (item.me_user_created.text == 'Sim'){
          this.get_credits(item.id);
        }
        this.get_proposals(item.id);
        $('#detailsSellerModal').modal({
          show: true,
          keyboard: false,
          backdrop: 'static',
        })
      },

      get_credits(id) {
        this.loadingCredits = true;
        this.seller.credits = '';

        axios.get('/api/user/get_credits_me/' + id, { headers: { Authorization: this.authorization } })
        .then(response => {
          console.log('get_credits');
          console.log(response.data);
          if (response.data.content.balance){
            this.seller.credits = response.data.content.balance;
          }
        })
        .catch(e => {
          var msg = 'Houve um erro ao buscar os créditos! Por favor, tente novamente.'
          this.$swal("Oops!", msg, "error")
          console.error(e)
        })
        .finally(() => {
          this.loadingCredits = false;
        })
      },

      get_proposals(id) {
        this.loadingProposals = true;
        this.seller.proposals = [];

        axios.get('/api/user/proposals/' + id, { headers: { Authorization: this.authorization } })
        .then(response => {
          console.log('get_proposals');
          console.log(response.data);
          if (response.data.content.length > 0){
            this.seller.proposals = response.data.content;
          }
        })
        .catch(e => {
          var msg = 'Houve um erro ao buscar as proposta do vendedor! Por favor, tente novamente.'
          this.$swal("Oops!", msg, "error")
          console.error(e)
        })
        .finally(() => {
          this.loadingProposals = false;
        })
      },

      classStatus (status){
        if(status == "Pendente") {
          return 'pendente';
        }

        if(status == "Recusada") {
          return 'recusada';
        }

        return 'aceita';
      },

      insertCredits(){
        this.loadingInsertCredits = true;
        this.seller.insertCredits = [];
        var dados = {
          'value': this.credits,
          'url': window.location.href
        }
        axios.post('/api/user/insert_credits_me/'+this.seller.id, dados, { headers: { Authorization: this.authorization }})
          .then(response => {
            console.log("insertCredits");
            console.log(response);
            if (response.data.content){
              this.seller.insertCredits = response.data.content;
            }
          })
          .catch(e => {
            var msg = 'Ocorreu um erro ao inserir créditos.';
            this.$swal("Oops!", msg, "error");
            console.error(e);
          })
          .finally(() => {
            this.loadingInsertCredits = false;
          })
      },

      changeFiltros () {
        //this.$refs.form.submit();
      },

      moneyToFloat (money) {
          if(!money || !money.length) {
            return false;
          }

          if(money.indexOf('R$') != -1) {
            money = money.split('R$')[1];
          }

          let toFloatNumber = '';

          for (let letter of money) {
            if(letter !== '.' && letter !== ',') {
              toFloatNumber += letter;
            }
          }

          let integerNumbers = '';
          for (let letter of toFloatNumber.substr(0, toFloatNumber.length - 2)) {
            if(letter !== ' ') {
              integerNumbers += letter;
            }
          }
          
          let decimalNumbers = toFloatNumber.substr(toFloatNumber.length - 2, toFloatNumber.length);
          
          return parseFloat(`${integerNumbers}.${decimalNumbers}`);
      },

      floatToMoney (money) {
        if(!money) {
          return false;
        }

        money = money.toLocaleString('pt-BR');

        if(money.indexOf('.') !== -1) {
          money = money.replace('.', ',');
        }

        if(money.indexOf(' ') !== -1) {
          money = money.replace(/ /g, '.');
        }

        if(money == '00') {
          money = '0,00';
        }

        return money;
      },

      checkDatePayment(installments){
        if (installments){
          var date = 'Confirmado';
          installments.forEach((value, index) => {
            if (!value.confirmed_payment && date == 'Confirmado'){
              date = value.receive_date;
            }
          });
          return date;
        }
      },
    },
    components: {
      VueCtkDateTimePicker
    },
  };
</script>

<style lang="scss" scoped>
  .mv-22 {
    margin: 22px 0;
  }
  .v-middle {
    vertical-align: middle !important;
  }

  .pendente{
    background-color: #FF8533;
    color: #fff;
  }

  .recusada{
    background-color: #dc3545;
    color: #fff;
  }

  .aceita{
    background-color: #01fe03;
    color: #000;
  }

  .row{
    margin: 0;
  }
</style>