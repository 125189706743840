<template>
  <div class="box box-widget">
    <div class="box-header">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <h3 class="box-title">{{title}}</h3>
        </div>
        <div class="col-9 box_filtros my-3">
          <form ref="form" method="GET" class="form-horizontal" :action="url">
          <div class="row">
            <div class="col-4">
              <select
                class="form-control box_input"
                v-model="status"
                name="status"
                @change="changeFiltros()"
                >
                <!-- <option value="">Status</option> -->
                <option
                  v-for="(option, index) in arrayStatus"
                  :key="index"
                  :value="option.value"
                >{{option.label}}</option>
              </select>
            </div>
            <div class="col-3" style="padding: 0;">
              <VueCtkDateTimePicker
                v-model="data_entrada"
                aria-label="Período"
                class="date-picker"
                locale="pt-BR"
                :auto-close="true"
                :no-header="true"
                :overlay="false"
                :range="true"
                :formatted="'ll'"
                :format="'YYYY-MM-DD'"
                :label="'Período'"
                :custom-shortcuts="labels"
              ></VueCtkDateTimePicker>
              <input v-if="data_entrada" type="hidden" name="date_init" :value="computedInitDate">
              <input v-if="data_entrada" type="hidden" name="date_finish" :value="computedFinishDate">
            </div>
            <div class="col-5">
              <div class="col-md-12 col-sm-12" style="padding: 0;">
                <div class="input-group">
                  <input
                    type="text"
                    name="busca"
                    class="form-control"
                    placeholder="Buscar por"
                    :value="busca"
                  />
                  <span class="input-group-btn">
                    <!-- <button type="submit" class="btn btn-primary btn-flat">
                      <i class="fa fa-search"></i>
                    </button> -->
                    <a
                      :href="url"
                      class="btn btn-default btn-flat"
                      data-toggle="tooltip"
                      title="Limpar Busca"
                      style="color:#7e7e7e;"
                    >
                      <i class="fa fa-times"></i>
                    </a>
                    <button type="submit" class="btn btn-primary btn-flat"><i class="fa fa-search" aria-hidden="true"></i></button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
    <div class="box-body">
      <slot></slot>

      <form
        v-if="items.data.length > 0"
        :class="formClass"
        v-on:submit.prevent="confirmDelete"
        :action="url + '/destroy'"
        method="POST"
        >
        <input type="hidden" name="_token" :value="token" />
        <input type="hidden" name="_method" value="DELETE" />
        <table id="example1" class="table table-bordered table-striped">
          <thead>
              <th v-for="(title, index) in titles" :key="index">{{title}}</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items.data" :key="index">
              <td v-for="(field, index) in item" :key="index" class="v-middle">
                <span v-if="field === null">{{field}}</span>
                <span v-else-if="!field.type && !field.payment">{{field}}</span>
                <span v-else-if="field.payment">{{checkDatePayment(field.installments)}}</span>                

                <span v-else-if="field.type == 'img'">
                  <img :src="field.src" style="max-width: 150px; max-height: 50px;" />
                </span>

                <span
                  v-else-if="field.type == 'badge'"
                  class="badge"
                  :class="classStatus(field.text)"
                >{{ field.text }}</span>

                <span
                  v-else-if="field.type == 'badge2'"
                  class="badge"
                  :class="'badge-' + field.status"
                >{{ field.text }}</span>

                <span
                  v-else-if="field.type == 'action'"
                  v-for="(action, index) in item.actions"
                  :key="index"
                >
                  <a
                    data-toggle="tooltip"
                    class="btn btn-flat ml-10"
                    :class="'btn-' + action.color"
                    :href="action.path"
                    :title="action.label"
                  >
                    <i :class="action.icon"></i>
                  </a>&nbsp;
                </span>
              </td>
              <td class="v-middle">
                <a
                  class="btn btn-flat btn-primary"
                  data-toggle="tooltip"
                  title="Detalhes"
                  @click="detailsProposal(item.id)"
                  style="cursor: pointer;"
                >
                  <i class="fa fa-eye" style="color:#fff;"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="(titles.length + 2)" align="left" vertical-align="center">
                <slot name="pagination"></slot>
              </td>
            </tr>
          </tfoot>
        </table>
      </form>

      <div v-if="items.data.length == 0" class="callout callout-default">
        <h4>Nenhum registro encontrado!</h4>
      </div>
    </div>



    <!-- MODAL DETALHES -->
    <div class="modal fade" id="detailsProposalModal" tabindex="-1" role="dialog" aria-labelledby="detailsProposalModalLabel">
      <div class="modal-dialog modal-lg" role="document" style="max-width: 90%;">
        <div class="modal-content">
          <div class="modal-header d-flex" style="align-items:center;padding: 16px;">
            <div class="col-5 p-0">
              <h4 class="modal-title" id="detailsProposalModal" v-if="proposal">
              Proposta {{proposal.id}}</h4>  
            </div>
            <div class="col-1 box_close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="proposal = ''">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="modal-body row" style="padding: 10px;">
            <div v-if="loadingProposal" class="d-flex justify-content-center box_loading" style="width: 100%; padding: 100px 0;">
              <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="col-12 p-0" v-if="proposal && !loadingProposal">
              <div class="col-12 px-0 py-3" v-if="proposal.seller_bank_info">
                <div class="row">
                  <div class="col-3">
                    <span class="font-weight-bold">Nome:</span>
                    {{proposal.seller_bank_info.name}}
                  </div>
                  <div class="col-3">
                    <span class="font-weight-bold">CPF:</span>
                    {{proposal.seller_bank_info.cpf}}
                  </div>
                  <div class="col-3">
                    <span class="font-weight-bold">CNPJ:</span>
                    {{proposal.seller_bank_info.cnpj}}
                  </div>
                </div>
              </div>
              <div class="col-12 px-0 py-3" v-if="proposal.seller_bank_info">
                <div class="row">
                  <div class="col-3">
                    <span class="font-weight-bold">Código do Banco:</span>
                    {{proposal.seller_bank_info.bank_code}}
                  </div>
                  <div class="col-3">
                    <span class="font-weight-bold">Nome do Banco:</span>
                    {{proposal.seller_bank_info.bank_name}}
                  </div>
                  <div class="col-3">
                    <span class="font-weight-bold">Agência:</span>
                    {{proposal.seller_bank_info.bank_agency}}
                  </div>
                </div>
              </div>
              <div class="col-12 px-0 py-3" v-if="proposal.seller_bank_info">
                <div class="row">
                  <div class="col-3">
                    <span class="font-weight-bold">Número da Conta:</span>
                    {{proposal.seller_bank_info.bank_account}}
                  </div>
                  <div class="col-3">
                    <span class="font-weight-bold">Tipo da Conta:</span>
                    {{proposal.seller_bank_info.type == 'SAVINGS' ? 'Poupança' : 'Corrente'}}
                  </div>
                  <div class="col-3">
                    <span class="font-weight-bold">Forma de pagamento:</span>
                    {{proposal.charge.payment == 'BOLETO' ? 'BOLETO' : 'CARTÃO DE CRÉDITO'}}
                  </div>
                </div>
              </div>
              <div class="col-12 px-0 py-3" v-if="proposal.charge">
                <div class="row">
                  <div class="col-6">
                    <span class="font-weight-bold">Detalhes:</span>
                    <a :href="proposal.charge.invoice_url" target="_blank" style="text-decoration: underline !important; color: #7e7e7e;">Ver Fatura</a>
                  </div>
                  <div class="col-6 text-right" v-if="proposal.status_asaas == 1 && proposal.me_order_id && !proposal.me_shipment_url">
                    <button
                      class="btn btn-flat btn-success"
                      @click="generateTicket()"
                      style="cursor: pointer;font-size: 15px;color:#fff;"
                      data-toggle="tooltip"
                      title="Alterar status e gerar etiqueta"
                      :disabled="loadinggenerateTicket"
                    >
                      <span v-if="!loadinggenerateTicket">Confirmar pagamento e Gerar etiqueta</span>
                      <div v-if="loadinggenerateTicket" class="spinner-grow spinner-grow-sm" role="status"></div>
                    </button>
                  </div>
                  <div class="col-6 text-right" v-if="proposal.status_asaas == 1 && proposal.me_order_id && proposal.me_shipment_url">
                    <a :href="proposal.me_shipment_url" target="_blank">
                      <button
                        class="btn btn-flat btn-success"
                        style="cursor: pointer;font-size: 15px;color:#fff;"
                        data-toggle="tooltip"
                        title="Acessar etiqueta"
                      >
                        <i class="fa fa-ticket" aria-hidden="true"></i>
                        Acessar Etiqueta
                      </button>
                    </a>
                  </div>
                  <div class="col-6 text-right text-danger" v-if="!proposal.me_order_id">
                    Ordem Melhor Envio não existente
                  </div>
                </div>
              </div>
              <div class="col-12" v-if="proposal.installment_charges">
                <table id="example1" class="table table-bordered table-striped">
                  <thead>
                      <th>#</th>
                      <th>Valor da Venda</th>
                      <th>Valor Recebido</th>
                      <th>Data de Pagamento</th>
                      <th><span class="font-weight-bold">Valor do Vendedor</span></th>
                      <th><span class="font-weight-bold">Data de Recebimento</span></th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in proposal.installment_charges" :key="index">
                      <td class="v-middle">
                        {{index+1}}/{{proposal.installment_charges.length}}
                      </td>
                      <td class="v-middle">
                        R$ {{item.value}}
                      </td>
                      <td class="v-middle">
                        R$ {{item.receive_value}}
                      </td>
                      <td class="v-middle">
                        {{item.payment_date ? item.payment_date : '-'}}
                      </td>
                      <td class="v-middle">
                        <span class="font-weight-bold">R$ {{item.seller_value}}</span>
                      </td>
                      <td class="v-middle">
                        <span class="font-weight-bold">{{item.receive_date ? item.receive_date : '-'}}</span>
                      </td>
                      <td class="v-middle">
                        {{item.status}}
                      </td>
                      <td class="v-middle">
                        <div v-if="!item.confirmed_payment" class="d-flex align-items-center">
                          <div class="pull-left" style="margin-right: 10px;">
                            <input style="display: block; font-size: 11px;" :id="'attachment_upload_' + `${index}`" type="file" name="file_charge" ref="attachment" v-on:change="handleFileUpload()" :disabled="index == 0 ? false : !proposal.installment_charges[index-1].confirmed_payment">
                          </div>
                          <button
                            class="btn btn-flat btn-primary"
                            @click="changePayment(proposal.id, item.id)"
                            style="cursor: pointer;font-size: 14px;"
                            :disabled="index == 0 ? false : !proposal.installment_charges[index-1].confirmed_payment"
                          >
                            <i class="fa fa-check" style="color:#fff;"></i>
                            Confirmar
                          </button>
                        </div>
                        <div v-if="item.confirmed_payment">
                          <i class="fa fa-check" style="color:#28a745;font-size: 20px;"></i>
                          <a class="pl-1 pr-3" style="color:#7e7e7e;text-decoration: underline !important;font-size:13px;" :href="item.attachment" target="_blank" data-toggle="tooltip" title="Ver anexo">
                            Ver anexo
                          </a>
                          <button
                            class="btn btn-flat btn-warning"
                            @click="changePayment(proposal.id, item.id, 'cancel')"
                            style="cursor: pointer;font-size: 15px;color:#fff;"
                            :disabled="index == 0 ? false : !proposal.installment_charges[index-1].confirmed_payment"
                            data-toggle="tooltip"
                            title="Cancelar pagamento"
                          >
                            <i class="fa fa-reply" style="color:#fff;"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-12">
                <button
                  class="btn btn-flat btn-secondary py-3"
                  @click="showDetails()">
                  Ver detalhes
                </button>
              </div>
              <div class="col-12 box_details_proposal" style="display: none;">
                <div class="col-12" style="padding: 0 10px;" v-for="(k, index) in proposal.keys" :key="index+k.key">
                  <div class="col-7 row line_details">
                    <div class="col-12 p-0 box_text">
                        Item: {{k.key}} - {{k.value}}
                    </div>
                    <div class="col-12 p-0">
                      <div class="box_image" v-for="(i, index2) in k.images" :key="index2+i" src="i" :style="{ 'background-image': 'url(\''+i+'\')'}" data-toggle="modal" data-target="#previewImageModal" @click="imagePreview = i"></div>
                    </div>
                  </div>
                </div>
                <div class="col-12 p-0">
                  <div class="col-12 box_subheader">
                    MEIO DE TRANSPORTE ESCOLHIDO
                  </div>
                </div>
                <div class="col-12 line_transport" v-for="(d, index) in proposal.delivery" :key="index+d.name">
                  <div class="col-7 box_interno row">
                    <div class="col-6 box_text">{{d.name}}</div>
                    <div class="col-3 box_value">
                      {{ d.days && d.days != '0' ? ` ${ d.days } dia(s)` : ' Indefinido'}}
                    </div>
                    <div class="col-3 box_value">
                    {{d.value == '0,00' ? 'Grátis' : `R$ ${ d.value }`}}</div>
                  </div>
                </div>
                <div class="col-12 p-0">
                  <div class="col-12 box_subheader">
                    MÉTODOS DE PAGAMENTO ESCOLHIDOS
                  </div>
                </div>
                <div class="col-12 line_transport">
                  <div class="col-7 p-0">
                    <div class="col-12 box_tarifa">
                      <i class="fa fa-barcode icon"></i>
                      <span>Boleto à vista</span>
                    </div>
                    <div class="col-12 box_tarifa">
                      <i class="fa fa-credit-card icon"></i>
                      <span>Cartão de Crédito em até {{proposal.credit_installments}}x</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

     <!-- MODAL VIZUALIZAÇÃO DE IMAGEM -->
    <div class="modal fade" id="previewImageModal" tabindex="-1" role="dialog" aria-labelledby="previewImageModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="imagePreview = ''">
                <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center" v-if="imagePreview">
            <img class="image_preview" :src="imagePreview">
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios';
  import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
  import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

  export default {
    props: [
      "title",
      "titles",
      "items",
      "items_complete",
      'authorization',
      "busca",
      "status",
      "url",
      "token",
      "formClass",
      "actions",
      "selected_init",
      "selected_finish",
    ],
    data: function() {
      return {
        deleteItems: [],
        proposal: '',
        loadingProposal: false,
        loadinggenerateTicket: false,
        btnDelete: {
          icon: "fa fa-trash",
          text: "Excluir registros selecionados",
          disabled: false
        },
        arrayStatus: [
          {value: 'todas', label: 'Todas'},
          {value: 'pendente', label: 'Pendente'},
          {value: 'recusada', label: 'Recusada'},
          {value: 1, label: 'Aguardando Pagamento'},
          {value: 2, label: 'Aguardando Envio'},
          {value: 3, label: 'Aguardando Entrega'},
          {value: 4, label: 'Concluído'},
          {value: 5, label: 'Pagamento Recebido'},
          {value: 6, label: 'Pagamento Parcial'},
        ],
        data_entrada: null,
        labels:[
          { key: 'thisDay', label: 'Hoje', value: 'day' },
          { key: 'lastDay', label: 'Ontem', value: '-day' },
          { key: 'last15Days', label: 'Últ. 15 dias', value: 15 },
          { key: 'thisMonth', label: 'Este mês', value: 'month' },
          { key: 'lastMonth', label: 'Mês passado', value: '-month' }
        ],
        attachment: '',
        imagePreview: '',
      };
    },
    created() {
      if (!this.items.data) {
        this.items.data = this.items;
      }
      if(this.selected_init != 'null'){
        this.data_entrada = { start: this.selected_init, end: this.selected_finish };
        console.log(this.data_entrada);
      }
    },
    computed: {
        total (toFloat = false) {
          if (this.proposal){
            let result = 0;
            let { value, delivery } = this.proposal;

            if(value) {
              result = this.moneyToFloat(value);
            }

            if(delivery.length && delivery[this.deliveryChoices] && delivery[this.deliveryChoices].value) {
              result += this.moneyToFloat(delivery[this.deliveryChoices].value);
            }

            /*if(toFloat) {
              return result;
            }*/

            return this.floatToMoney(result);
          }
        },
        computedInitDate() {
          return this.data_entrada.start;
        },
        computedFinishDate() {
          return this.data_entrada.end;
        },
      },
    methods: {
      detailsProposal: function(id) {
        /*this.items_complete.forEach((value, index) => {
          if (value.id == id){
            this.proposal = value;
          }
        });*/
        this.get_proposal(id);
        console.log("this.proposal");
        console.log(this.proposal);
        $('.box_details_proposal').hide();
        $('#detailsProposalModal').modal({
          show: true,
          keyboard: false,
          backdrop: 'static',
        })
      },
      classStatus (status){
        if(status == "Pendente") {
          return 'pendente';
        }

        if(status == "Recusada") {
          return 'recusada';
        }

        return 'aceita';
      },

      handleFileUpload(){
        this.attachment = event.target.files[0];
      },

      clickSelectFiles(index) {
        $('#attachment_upload_' + index).val('');
        $('#attachment_upload_' + index).trigger('click');
      },

      showDetails() {
        $('.box_details_proposal').slideToggle('');
      },

      changePayment(id, installment_id, cancel){
        let formData = new FormData();
        var msg = '';
        var msg2 = '';
        if (!cancel){
          msg = "Deseja confirmar o pagameto?";
          msg2 = "Pagamento confirmado";
          formData.append('confirm', true);
          if (this.attachment.name) {
            let file = this.attachment;
            formData.append(
              `attachment`,
              file
            );
          } else {
            this.$swal("Oops!", "Faça o upload do comprovante", "warning");
            return null;
          }
        } else {
          msg = "Deseja cancelar o pagameto?";
          msg2 = "Pagamento cancelado";
          formData.append('cancel', true);
        }
        formData.append('installment_id', installment_id);

        this.$swal({
          title: "Atenção!",
          text: msg,
          type: "warning",
          showCancelButton: true,        
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
        }).then(result => {
          if (result.value) {
            axios.post('/api/proposal/'+id+'/update', formData, { headers: { Authorization: this.authorization }})
            .then(response => {
              if (response.data.content){
                this.get_proposal(id, 'update');
                this.$swal({
                  title: "Sucesso!",
                  text: msg2,
                  imageUrl: "/img/sucesso.png",
                  imageWidth: 200,
                });
              }
            })
            .catch(e => {
              var msg = 'Ocorreu um erro ao confirmar o pagamento.';
              this.$swal("Oops!", msg, "error");
              console.error(e);
            })
            .finally(() => {
              //this.loadingAction = false;
            })
          }
        });
      },

      get_proposal(id, update) {
        this.loadingProposal = true;
        this.proposal = '';

        axios.get('/api/proposal/show/' + id, { headers: { Authorization: this.authorization } })
        .then(response => {
          console.log('get_proposal');
          console.log(response.data.content);
          if (response.data.content){
            this.proposal = response.data.content;
          }
          if (update){
            this.items.data.forEach((value, index) => {
              if (value.id == id){
                this.items.data[index].status = {
                  "type" : 'badge',
                  "status" : 'primary',
                  "text": response.data.content.status,
                };
              }
            });
          }
        })
        .catch(e => {
          var msg = 'Houve um erro ao buscar a proposta! Por favor, tente novamente.'
          this.$swal("Oops!", msg, "error")
          console.error(e)
        })
        .finally(() => {
          this.loadingProposal = false;
        })
      },

      changeFiltros () {
        //this.$refs.form.submit();
      },

      moneyToFloat (money) {
          if(!money || !money.length) {
            return false;
          }

          if(money.indexOf('R$') != -1) {
            money = money.split('R$')[1];
          }

          let toFloatNumber = '';

          for (let letter of money) {
            if(letter !== '.' && letter !== ',') {
              toFloatNumber += letter;
            }
          }

          let integerNumbers = '';
          for (let letter of toFloatNumber.substr(0, toFloatNumber.length - 2)) {
            if(letter !== ' ') {
              integerNumbers += letter;
            }
          }
          
          let decimalNumbers = toFloatNumber.substr(toFloatNumber.length - 2, toFloatNumber.length);
          
          return parseFloat(`${integerNumbers}.${decimalNumbers}`);
      },

      floatToMoney (money) {
        if(!money) {
          return false;
        }

        money = money.toLocaleString('pt-BR');

        if(money.indexOf('.') !== -1) {
          money = money.replace('.', ',');
        }

        if(money.indexOf(' ') !== -1) {
          money = money.replace(/ /g, '.');
        }

        if(money == '00') {
          money = '0,00';
        }

        return money;
      },

      checkDatePayment(installments){
        if (installments){
          var date = 'Confirmado';
          installments.forEach((value, index) => {
            if (!value.confirmed_payment && date == 'Confirmado'){
              date = value.receive_date;
            }
          });
          return date;
        }
      },

      generateTicket(){
        this.$swal({
          title: "Atenção!",
          text: "Deseja realmente confirmar o pagamento da proposa?",
          type: "warning",
          showCancelButton: true,        
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
        }).then(result => {
          if (result.value) {
            this.loadinggenerateTicket = true;
            var dados = {
              'id': this.proposal.id
            }
            axios.post('/api/proposal/generate_ticket', dados, { headers: { Authorization: this.authorization }})
              .then(response => {
                console.log("generateTicket");
                console.log(response);
                if (response.data.content.print){
                  if (response.data.content.print.url){
                    this.get_proposal(this.proposal.id, 'update');
                    this.$swal({
                      title: "Sucesso!",
                      text: "Etiqueta gerada com sucesso!",
                      imageUrl: "/img/sucesso.png",
                      imageWidth: 200,
                    });
                  }
                } else {
                  console.log("errrouu");
                  var msg = 'Ocorreu um erro ao gerar o ticket.';
                  if (response.data.content.checkout){
                    msg += "\n\n"+response.data.content.checkout.error;
                  }
                  this.$swal("Oops!", msg, "error");
                }
              })
              .catch(e => {
                console.log("e");
                console.log(e);
                var msg = 'Ocorreu um erro ao gerar o ticket.';
                this.$swal("Oops!", msg, "error");
                console.error(e);
              })
              .finally(() => {
                this.loadinggenerateTicket = false;
              })
          }
        });
      },
    },
    components: {
      VueCtkDateTimePicker
    },
  };
</script>

<style lang="scss" scoped>
  .mv-22 {
    margin: 22px 0;
  }
  .v-middle {
    vertical-align: middle !important;
  }
  a{
    color: #fff;
  }

  .pendente{
    background-color: #FF8533;
    color: #fff;
  }

  .recusada{
    background-color: #dc3545;
    color: #fff;
  }

  .aceita{
    background-color: #01fe03;
    color: #000;
  }

  .row{
    margin: 0;
  }
  #detailsProposalModal, #newProposalModal, #followProposalModal{
      .modal-content{
        border: 0;
      }

      .box_anexo{
        float:left;
        font-size: 22px;
        line-height: 36px;
        margin-right: 15px;
        cursor: pointer;
        background: none;
        border: 0;
        color: #7e7e7e;
        padding: 0;
      }

      .modal-header{
        background-color: #333;
        color: #fff;
        padding: 16px 0;

        .box_close{
          padding: 0 16px;

          .close{
            color: #fff;
          }
        }

        .box_name {
          color: #000;
          background-color: #fff;
          border-bottom-right-radius: 30px;
          border-top-right-radius: 30px;
          padding: 5px 10px;
          font-size: 16px;
          width: 70%;
        }

        h4{
          padding: 0 10px;
        }

        .box_info{
          padding: 0;
          align-items: center;

          .box_icon{
            float: left;

            img{
              width: 40px;
              margin-right: 10px;
            }
          }
          .box_text{
            float: left;
            font-size: 14px;
            line-height: 1.2;

            span {
              line-height: 1.2;
            }
          }
        }
      }

      .modal-footer{
        background-color: #333;
        padding: 30px 0;
        justify-content: flex-start;

        .box_total{
          padding: 0;
          margin: 0;
          line-height: 1.2;

          .box_text {
            color: #fff;
            font-size: 14px;
            padding: 0 10px;
          }

          .box_value {
            color: #000;
            background-color: #fff;
            border-bottom-right-radius: 30px;
            border-top-right-radius: 30px;
            width: 150px;
            text-align: center;
            padding: 2px;
            font-size: 16px;
          }
        }

        .box_publicidade{
          width: 400px;
          background-color: #fff;
          text-align: center;
          border-radius: 5px;
          height: 70px;
          align-items: center;
          display: flex;
          justify-content: center;
        }
      }

      .modal-body{
        padding: 0;

        .box_subheader{
          background-color: #333;
          color: #fff;
          padding: 10px;
          margin-top: 10px;
        }

        .line_transport{
          color: #000;
          font-size: 14px;
          padding: 10px;

          .box_interno {
            border-bottom: 1px solid #333;
            padding: 10px;

            .box_text{
              padding: 0;
            }

            .box_value{
              padding: 0;
              border-radius: 15px;
              background-color: #e7e7e7;
              text-align: center;
            }

            .box_value_checked {
              background-color: #01fe03;
            }

            .box_check_frete{
              padding: 0;
              text-align: left;
              font-size: 13px;
              text-align: center;
              cursor: pointer;

              .fa-check-square-o {
                color: #01fe03;
              }
            }
          }

          .box_check {
            width: 35px;
            border-radius: 15px;
            background-color: #e6e6e6;
            text-align: center;
            font-size: 20px;
            margin-right: 10px;
            height: 20px;

            i{
              display: none;
            }
          }
          .box_checked {
            background-color: #01fe03;
            color: #fff;

            i {
              display: block;
            }
          }
        }

        .box_input_tarifa {
          border-radius: 20px;
          color: #000;
          padding: 5px;
          font-size: 13px;
          height: 30px;
        }

        .box_tarifa {
          border-radius: 20px;
          color: #000;
          padding: 0;
          height: 30px;
          margin-bottom: 5px;
          background-color: #e6e6e6;

          .icon {
            background-color: #f8f8f8;
            border: 1px solid #a3a3a3;
            height: 30px;
            width: 40px;
            border-radius: 15px;
            line-height: 30px;
            text-align: center;
            float: left;
          }

          .check {
            float:right;
            line-height: 30px;
            margin-right: 20px;
          }

          .fa-check-square-o {
            color: #01fe03;
          }

          span{
            line-height: 30px;
            padding-left: 5px;
          }
        }

        .line_details{
          padding: 10px;
          border-bottom: 1px solid #333;
          margin-bottom: 5px;

          .box_text{
            color: #000;
            font-size: 13px;
            margin-bottom: 5px;
            padding: 0;
            float: left;

            .box_check {
              width: 35px;
              border-radius: 15px;
              background-color: #e6e6e6;
              text-align: center;
              font-size: 20px;
              margin-right: 10px;
              height: 20px;

              i{
                display: none;
              }
            }
            .box_checked {
              background-color: #01fe03;
              color: #fff;

              i {
                display: block;
              }
            }
          }

          .box_image{
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            border: 1px solid #333;
            background-color: #fff;
            margin: 0 5px;
            box-shadow: 1px 1px 3px #ccc;
            float:left;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }

          .box_fields {
            padding: 15px 0 10px 0;

            .box_input {
              padding: 0;
              padding-right: 10px;

              .input {
                border-radius: 20px;
                text-align:center;
                border: 1px solid #bbbbbb;
                color: #000;
              }
            }

            .box_simulator {
              padding: 0;
              padding-right: 10px;

              .input {
                border-radius: 20px;
                text-align:center;
                border: 1px solid #01fe03;
                color: #000;
                background-color: #01fe03;
              }
            }
          }
        }

        .box_frete{
          padding: 10px;

          .box_fields {
            padding: 0;
            padding-bottom: 15px;
            margin-bottom: 15px;

            border-bottom: 1px solid #333;

            .box_interno{
              padding: 0;

              .box_text{
                color: #000;
                font-size: 13px;
                text-align: center;
                padding-right: 10px;
              }

              .box_input {
                padding: 0;
                padding-right: 10px;
                margin-bottom: 5px;

                .input {
                  border-radius: 20px;
                  text-align:center;
                  border: 1px solid #bbbbbb;
                  color: #000;
                }
              }
            }
          }

          .box_button_busca {
            padding: 15px 0;
            justify-content: center;

            .btn {
              border-radius: 20px;
              text-align:center;
              border: 1px solid #01fe03;
              background-color: #01fe03;
              color: #000;
              width: 200px;
            }
          }
        }

        .line_detalhe_proposta{
          padding: 30px;
          padding-bottom: 20px;

          .box_detalhe{
            padding: 0;

            .badge_name {
              background-color: #333;
              color: #fff;
              border-radius: 35px;
              text-align: center;
              position: absolute;
              top: -20px;
              left: 8px;
              font-size: 13px;
              padding: 1px 18px;
            }

            .badge_icon {
              background-color: #FF8533;
              color: #fff;
              padding: 0;
              border-radius: 100%;
              text-align: center;
              position: absolute;
              top: -25px;
              left: -15px;
              font-size: 18px;
              height: 35px;
              width: 35px;
              line-height: 35px;
            }

            .input{
              border: 1px solid #333;
            }

            .badge_check {
              width: 50px;
              height: 36px;
              border-radius: 30px;
              background-color: #01fe03;
              color: #fff;
              text-align: center;
              font-size: 26px;
              position: absolute;
              right: 1px;
              top: 1px;
              line-height: 38px;
            }

            .boleto{
              border: 1px solid #01fe03;
              color: #fff;
              background-color: #01fe03;
              width: 150px;
              margin: 0 auto;
              margin-top: 10px;
            }
          }
        }

        .box_shortcut_chat{
          display: inline-flex;
          align-items: center;
          padding: 0 30px;
          margin-bottom: 20px;

          a{
            display: inline-flex;
            color: #000;
          }

          .box_icon{
            width: 45px;
            height: 45px;
            line-height: 45px;
            background-color: #FF8533;
            color: #fff;
            padding: 0;
            border-radius: 100%;
            text-align: center;
            font-size: 28px;
            margin-right: 10px;
          }

          .box_text{
            font-size: 13px;
            line-height: 1.3;
          }
        }

        .box_acompanhamento{
          color: #000;
          font-size: 14px;
          padding: 20px 0 10px 0;
          display: flex;
          justify-content: center;
          align-items: center;

          .box_interno{

            .box_status{
              padding: 0;
              float: left;
              display: grid;

              .box_icon {
                display: flex;
                align-items: center;
                width: 120px;

                .box_circle {
                  width: 60px;
                  height: 60px;
                  border: 5px solid #000;
                  border-radius: 100%;
                  float: left;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 28px;
                  margin: 0 auto;

                  .fa-truck{
                    -moz-transform: scaleX(-1);
                    -o-transform: scaleX(-1);
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                  }

                  img{
                    width: 28px;
                  }
                }
              }

              .box_icon:after {
                position: relative;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                height: 5px;
                content: "";
                background-color: #000;
              }

              .box_icon:before {
                position: relative;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                height: 5px;
                content: "";
                background-color: #000;
              }

              .box_text{
                width: 100%;
                float: left;
                text-align:center;
                padding: 0;
                padding-top: 5px;
                font-size: 13px;
              }
            }

            .status_confirmado {

              .box_circle {
                border-color: #01fe03 !important;
              }

              i {
                color: #01fe03 !important;
              }

              .box_icon:after {
                background-color: #01fe03;
              }

              .box_icon:before {
                background-color: #01fe03;
              }
            }

            .end_gradient {
              .box_icon:after {
                background: linear-gradient(90deg, rgba(1,254,3,1) 0%, rgba(0,0,0,1) 100%) !important;
              }
            }

            .status_first_confirmado {
              .box_icon:before {
                background-color: #01fe03;
              }
            }

            .status_cancelado {

              .box_circle {
                border-color: #dc3545 !important;
              }

              i {
                color: #dc3545 !important;
              }

              .box_icon:after {
                background: linear-gradient(90deg, rgba(220,53,69,1) 0%, rgba(0,0,0,1) 100%) !important;
              }

              .box_icon:before {
                background-color: #dc3545;
              }
            }

          }

          .box_esquerdo {
            position: relative;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            height: 5px;
            content: "";
            background-color: #000;
            margin-bottom: 24px;
          }

          .box_direito {
            position: relative;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            height: 5px;
            content: "";
            background-color: #000;
            margin-bottom: 24px;
          }

          .background_confirmado {
            background-color: #01fe03;
          }

          .background_cancelado {
            background-color: #dc3545;
          }

        }

        .box_legenda {
          padding: 0;
          padding-top: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          .box_interno{

            .box_item{
              display: inline-flex;
              align-items: center;

              .box_cor{
                border-radius: 100%;
                width:10px;
                height:10px;
                margin-right: 5px;
                background-color: #000;
                box-shadow: 1px 1px 3px #ccc;
              }

              .box_text{
                color: #000;
                font-size: 12px;
                margin-right: 20px;
              }
            }
          }
        }

        .box_actions{
          padding: 15px 0 20px 0;
          display: grid;
          justify-content: center;
          align-items: center;

          input{
            margin-bottom: 10px;
            width: 300px;
          }

          button{
            border: 1px solid #01fe03;
            color: #fff;
            background-color: #01fe03;
            width: 170px;
            cursor: pointer;            
          }
        }
      }
    }
</style>