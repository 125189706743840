<template>
  <div>
    <div class="modal-footer d-flex">
      <button class="btn" @click="getMatchesLength()" :disabled="loadingAvaliate">
        <span v-if="!loadingAvaliate">Buscar Matches</span>
        <div v-if="loadingAvaliate" class="spinner-grow spinner-grow-sm" role="status"></div>
      </button>
    </div>
    <div class="text-center">
      <span>Essa publicidade atingirá <b>{{ matches }}</b> usuários</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data: function() {
    return {
      loadingAvaliate: false,
      matches: 0,
    };
  },
  methods: {
    getInputsValues() {
      const names = [
        'package_id',
        'cep_initial',
        'cep_final',
        'user_age_initial',
        'user_age_final',
        'user_facebook',
        'user_google',
        'user_type_buyer',
        'user_type_seller'
      ];

      let data = {};
      names.map((n) => {
        data[n] = document.querySelector(`#${n}`).value;
      });

      return data;
    },
    getMatchesLength(){
      this.loadingAvaliate = true;

      let data = this.getInputsValues();
      
      data['user_type'] = null;

      if (data['user_type_buyer'] && data['user_type_buyer'] == 1) {
        data['user_type'] = 1;
      }

      if (data['user_type_seller'] && data['user_type_seller'] == 1) {
        data['user_type'] = 2;
      }

      if (data['user_type_buyer'] && data['user_type_seller'] && data['user_type_buyer'] == 1 && data['user_type_seller'] == 1) {
        data['user_type'] = 2;
      }

      axios.post('/cms/publicities/matches', data)
        .then(response => {
          console.log("avaliateUser");
          console.log(response);
          this.loadingAvaliate = false;
          this.matches = response.data.length;
        })
        .catch(e => {
          this.loadingAvaliate = false;
          var msg = 'Ocorreu um erro ao fazer a avaliação.'
          this.$swal("Oops!", msg, "error")
          console.error(e.response)
        })
    },
  }
};
</script>
<style lang="scss" scoped>

.modal-footer{
  justify-content: center;

  .btn {
    border-radius: 20px;
    text-align:center;
    border: 1px solid #01fe03;
    background-color: #01fe03;
    color: #000;
    width: 200px;
  }
}

b {
  font-weight: bold !important;
}

</style>