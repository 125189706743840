<template>
  <div class="col-12 box_list_proposal">
    <div class="row align-items-center">
      <div class="col-12 padding_none">
        <div class="col-12 d-flex box_header">
          <h4 v-if="listaGeral">Veja todas as Propostas</h4>  
          <h4 v-if="!listaGeral">Propostas da Solicitação {{solicitation}}</h4>  
        </div>
      </div>
      <div class="col-12 box_filtros my-3">
        <div class="row">
          <div class="col-3">
            <select
              class="form-control box_input"
              v-model="filter.status"
              @change="listProposals()"
              >
              <option value="">Status</option>
              <option
                v-for="(option, index) in arrayStatus"
                :key="index"
                :value="option.value"
              >{{option.label}}</option>
            </select>
          </div>
          <div class="col-3">
            <input
              class="form-control box_input"
              type="search"
              v-model="filter.search"
              placeholder="Buscar..."
              />
          </div>
        </div>
      </div>
      <div v-if="loadingProposals" class="d-flex justify-content-center box_loading col-6">
        <div class="spinner-grow text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="col-12 box_proposals">
        <div v-for="(p, index) in filteredProposals" :key="p.id" v-if="!loadingProposals">
          <div class="col-6 box" @click="modalStatusProposal(p)">
            {{p.solicitation.vehicle}} - R$ {{p.value}} - {{p.created_at}}
            <div class="badge_name">
              Proposta {{p.id}}
            </div>
            <div class="badge_status" :class="classStatus(p.status)">
              {{p.status}}
            </div>
          </div>
        </div>
        <div v-if="!loadingProposals && !filteredProposals.length" class="col-12 box_sem_registro">
          Nenhuma proposta disponível
        </div>
      </div>
      <div class="d-flex box_button" @click="newProposal()" v-if="!listaGeral">
        <div class="box_icon">
          <i class="fa fa-usd"></i>
        </div>
        <div class="box_text">
          CRIAR PROPOSTA
        </div>
      </div>
    </div>

    <!-- MODAL DETALHES DO PROPOSTA -->
    <div class="modal fade" id="detailsProposalModal" tabindex="-1" role="dialog" aria-labelledby="detailsProposalModalLabel">
      <div class="modal-dialog modal-lg" role="document" style="max-width: 60%;">
        <div class="modal-content">
          <div class="modal-header d-flex" style="align-items:center;padding: 16px;">
            <div class="col-5 padding_none">
              <h4 class="modal-title" id="detailsProposalModal" v-if="proposal">
              Proposta {{proposal.id}}</h4>  
            </div>
            <div class="col-6 d-flex box_info" v-if="detailsSolicitation">
              <div class="box_icon">
                <img src="/img/button_pecas_color.png">
              </div>
              <div class="box_text">
                <span>Veículo: {{detailsSolicitation.vehicle}}</span></br>
                <span>Categoria: {{detailsSolicitation.category.name}}</span></br>
                Subcategorias: 
                <span v-for="(sub, index) in detailsSolicitation.subcategories" :key="index+sub.name">
                  {{sub.name}}
                  <span v-if="index+1 < detailsSolicitation.subcategories.length">/</span>
              </span></br>
                <span>Preferência:</span>
                <span v-if="detailsSolicitation.new">Novo</span>
              <span v-if="detailsSolicitation.semi_new">Seminovo</span>
              <span v-if="!detailsSolicitation.new && !detailsSolicitation.semi_new">Não especificado</span>
              </div>
            </div>
            <div class="col-1 box_close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="proposal = ''">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="modal-body row" v-if="proposal">
            <div class="col-12" style="padding: 0 10px;" v-for="(k, index) in proposal.keys" :key="index+k.key">
              <div class="col-7 row line_details">
                <div class="col-12 padding_none box_text">
                    Item: {{k.key}} - {{k.value}}
                </div>
                <div class="col-12 padding_none">
                  <div class="box_image" v-for="(i, index2) in k.images" :key="index2+i" src="i" :style="{ 'background-image': 'url(\''+i+'\')'}" data-toggle="modal" data-target="#previewImageModal" @click="imagePreview = i"></div>
                </div>
              </div>
            </div>
            <div class="col-12 padding_none">
              <div class="col-12 box_subheader">
                MEIO DE TRANSPORTE ESCOLHIDO
              </div>
            </div>
            <div class="col-12 line_transport" v-for="(d, index) in proposal.delivery" :key="index+d.name">
              <div class="col-7 box_interno row">
                <div class="col-6 box_text">{{d.name}}</div>
                <div class="col-3 box_value">
                  {{ d.days && d.days != '0' ? ` ${ d.days } dia(s)` : ' Indefinido'}}
                </div>
                <div class="col-3 box_value">
                {{d.value == '0,00' ? 'Grátis' : `R$ ${ d.value }`}}</div>
              </div>
            </div>
            <div class="col-12 padding_none">
              <div class="col-12 box_subheader">
                MÉTODOS DE PAGAMENTO ESCOLHIDOS
              </div>
            </div>
            <div class="col-12 line_transport">
              <div class="col-7 padding_none">
                <div class="col-12 box_tarifa">
                  <i class="fa fa-barcode icon"></i>
                  <span>Boleto à vista</span>
                </div>
                <div class="col-12 box_tarifa">
                  <i class="fa fa-credit-card icon"></i>
                  <span>Cartão de Crédito em até {{proposal.credit_installments}}x</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="box_total">
              <div class="box_text">
                TOTAL DA VENDA:
              </div>
              <div class="box_value">
                R$ {{total}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL NOVA PROPOSTA -->
    <div class="modal fade" id="newProposalModal" tabindex="-1" role="dialog" aria-labelledby="newProposalModalLabel">
      <div class="modal-dialog modal-lg" role="document" style="max-width: 60%;">
        <div class="modal-content">
          <div class="modal-header d-flex" style="align-items:center;">
            <div class="col-5 padding_none">
              <div class="box_name">
                Olá {{user.name}}
              </div>
              <h4 class="modal-title" id="newProposalModal">
              Confira os detalhes da sua venda!</h4>  
            </div>
            <div class="col-6 d-flex box_info" v-if="detailsSolicitation">
              <div class="box_icon">
                <img src="/img/button_pecas_color.png">
              </div>
              <div class="box_text">
                <span>Veículo: {{detailsSolicitation.vehicle}}</span></br>
                <span>Categoria: {{detailsSolicitation.category.name}}</span></br>
                Subcategorias: 
                <span v-for="(sub, index) in detailsSolicitation.subcategories" :key="index+sub.name">
                  {{sub.name}}
                  <span v-if="index+1 < detailsSolicitation.subcategories.length">/</span>
                </span></br>
                  <span>Preferência:</span>
                  <span v-if="detailsSolicitation.new">Novo</span>
                <span v-if="detailsSolicitation.semi_new">Seminovo</span>
                <span v-if="!detailsSolicitation.new && !detailsSolicitation.semi_new">Não especificado</span>
              </div>
            </div>
            <div class="col-1 box_close" style="padding: 0 32px;">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="proposal = ''">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="modal-body row" v-if="detailsSolicitation">
            <div class="col-12" style="padding: 0 10px;" v-for="(k, index) in detailsSolicitation.keys" :key="index+k.id">
              <div class="col-7 row line_details">
                <div class="box_text" @click="checkItem(k.id, index)" style="cursor: pointer;">
                  <div class="pull-left box_check" :id="'box_check_' + k.id">
                    <i class="fa fa-check"></i>
                  </div>
                  <div class="pull-left">
                    Item: {{k.key}}
                  </div>
                </div>
                <div class="col-12 padding_none">
                  <div class="box_image" v-for="(i, index2) in k.images" :key="index2+i" src="i" :style="{ 'background-image': 'url(\''+i+'\')'}" data-toggle="modal" data-target="#previewImageModal" @click="imagePreview = i"></div>
                </div>
                <div class="col-12 box_fields row">
                  <div class="col-5 box_simulator">
                    <!-- <a :href="'/cms/simulator?value='+k.value" target="_blank">
                      <div class="form-control input">
                        Simulador de Venda
                      </div>
                    </a> -->
                    <div class="form-control input" @click="modalSimulator(index, k.value, k.id)">
                      Simulador de Venda
                    </div>
                  </div>
                  <div class="col-5 box_input">
                    <!-- <money class="form-control input background" v-model="k.value" v-bind="moneyFormat"></money> -->
                    <input v-model.lazy="k.value" v-money="moneyFormat" class="form-control input background" @keyup="loadPaymentSimulation();checkItem(k.id, index, true)"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 padding_none">
              <div class="col-12 box_subheader">
                MÉTODOS DE PAGAMENTO
              </div>
            </div>
            <div class="col-12 line_transport">
              <div class="col-7 padding_none">
                <div class="col-12 box_text">
                  Como você quer receber?
                </div>
                <div class="col-12 box_tarifa" style="cursor: pointer;" @click="setCheckPaymentChoices('BOLETO')">
                  <i class="fa fa-barcode icon"></i>
                  <span>Boleto à vista</span>
                  <i class="fa check" v-bind:class="{ 'fa-check-square-o': checkPaymentChoices('BOLETO'), 'fa-square-o': !checkPaymentChoices('BOLETO') }"></i>
                </div>
                <div class="col-12 box_tarifa" style="cursor: pointer;" @click="setCheckPaymentChoices('CREDIT_CARD')">
                  <i class="fa fa-credit-card icon"></i>
                  <span>Cartão de Crédito</span>
                  <i class="fa check" v-bind:class="{ 'fa-check-square-o': checkPaymentChoices('CREDIT_CARD'), 'fa-square-o': !checkPaymentChoices('CREDIT_CARD') }"></i>
                </div>
                <div class="col-12 box_tarifa" v-if="checkPaymentChoices('CREDIT_CARD')">
                  <div class="pull-left" style="width: 30%;">
                    <i class="fa fa-cog icon"></i>
                    <span>Em até:</span>
                  </div>
                  <div class="pull-left" style="width: 70%;">
                    <select class="form-control box_input_tarifa" v-model="paymentCardPlot">
                      <option value="" selected="true">Selecione</option>
                      <option :value="index" v-for="(p, index) in paymentCardSimulations" :key="index">
                        {{p.portion}}x de R$ {{p.value_plots}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 padding_none">
              <div class="col-12 box_subheader">
                SIMULE SEU FRETE
              </div>
            </div>
            <div class="col-12 padding_none">
              <div class="col-6 box_frete row">
                <div class="col-12 box_tarifa">
                  <i class="fa fa-map-signs icon" style="color: #7e7e7e;"></i>
                  <span class="font-weight-bold">Destino: </span>
                  <span>{{contact_cep}}</span>
                </div>
                <div class="col-12 box_tarifa">
                  <div class="pull-left" style="width: 30%;">
                    <i class="fa fa-map-signs icon" style="color: #7e7e7e;"></i>
                    <span class="font-weight-bold">Remetente:</span>
                  </div>
                  <div class="pull-left" style="width: 70%;">
                    <select class="form-control box_input_tarifa" v-model="senderCepIndex">
                      <option value="null">Selecione</option>
                      <option :value="index" v-for="(p, index) in adresses" :key="index">
                        {{p.cep}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12 box_fields row">
                  <div class="col-6 box_interno row">
                    <div class="col-12 box_text">ALTURA - CM</div>
                    <div class="col-12 box_input">
                      <input type="number" v-model="data.height" placeholder="Digite aqui..." class="form-control input background"/>
                    </div>
                  </div>
                  <div class="col-6 box_interno row">
                    <div class="col-12 box_text">LARGURA - CM</div>
                    <div class="col-12 box_input">
                      <input type="number" v-model="data.width" placeholder="Digite aqui..." class="form-control input background"/>
                    </div>
                  </div>
                  <div class="col-6 box_interno row">
                    <div class="col-12 box_text">COMPRIMENTO - CM</div>
                    <div class="col-12 box_input">
                      <input type="number" v-model="data.weight" placeholder="Digite aqui..." class="form-control input background"/>
                    </div>
                  </div>
                  <div class="col-6 box_interno row">
                    <div class="col-12 box_text">PESO - GRAMAS</div>
                    <div class="col-12 box_input">
                      <input type="number" v-model="data.length" placeholder="Digite aqui..." class="form-control input background"/>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex box_button_busca">
                  <button class="btn" @click="loadDeliveryMethods()" :disabled="loadingSearch">
                    <span v-if="!loadingSearch">Buscar</span>
                    <div v-if="loadingSearch" class="spinner-grow spinner-grow-sm" role="status"></div>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 padding_none" v-if="deliveryMethodsSearched">
              <div class="col-12 box_subheader">
                ESCOLHA O MEIO DE TRANSPORTE
              </div>
            </div>
            <div class="col-12 line_transport" v-for="(d, index) in deliveryMethods" :key="index+d.Carrier"  style="padding: 0 10px;">
              <div class="col-10 box_interno row" style="cursor: pointer;">
                <div class="col-6 box_text" @click="setCheckDeliveryChoices(index)">
                  <div class="pull-left box_check" :class="{'box_checked' : checkDeliveryChoices(index)}" :id="'box_check_transport_' + d.ServiceCode">
                    <i class="fa fa-check"></i>
                  </div>
                  {{d.Carrier}} {{d.ServiceDescription}}
                </div>
                <div class="col-2 box_value" @click="setCheckDeliveryChoices(index)" :class="{'box_value_checked' : checkDeliveryChoices(index)}">
                  {{ d.OriginalDeliveryTime && d.OriginalDeliveryTime != '0' ? ` ${ d.OriginalDeliveryTime } dia(s)` : ' Indefinido'}}
                </div>
                <div class="col-2 box_value" @click="setCheckDeliveryChoices(index)" :class="{'box_value_checked' : checkDeliveryChoices(index)}">
                  <!-- {{ d.ShippingPrice && d.ShippingPrice != '0' ? ` R$ ${ d.ShippingPrice }` : 'Preço não informado'}} -->
                  {{ d.ShippingPrice && d.ShippingPrice != '0' && !d.frete ? ` R$ ${ d.ShippingPrice }` : d.frete ? 'Grátis' : 'Preço não informado'}}
                </div>
                <div class="col-2 box_check_frete" @click="setFreteGratis(d, index)">
                  <i class="fa check" v-bind:class="{ 'fa-check-square-o': d.frete, 'fa-square-o': !d.frete }"></i> Não cobrar frete
                </div>
              </div>
            </div>
            <div class="col-12 line_transport" v-if="deliveryMethodsSearched">
              <div class="col-10 box_interno row" style="border: 0; padding: 0 10px;">
                <div class="col-6 box_text" @click="setCheckDeliveryChoices(deliveryMethods.length)" style="cursor: pointer;">
                  <div class="pull-left box_check" :class="{'box_checked' : checkDeliveryChoices(deliveryMethods.length)}">
                    <i class="fa fa-check"></i>
                  </div>
                  Entrega Fixa
                </div>
                <div class="col-12 box_frete" v-if="checkDeliveryChoices(deliveryMethods.length)">
                  <div class="col-12 box_fields row" style="border: 0;padding: 0;margin: 0;">
                    <div class="col-5 box_interno" style="border: 0;">
                      <div class="col-12 box_input">
                        <input type="number" v-model="deliveryFixed.days" placeholder="Dias para entrega" class="form-control input background"/>
                      </div>
                    </div>
                    <div class="col-5 box_interno" style="border: 0;">
                      <div class="col-12 box_input">
                        <money class="form-control input background" placeholder="Valor (R$)" v-model="deliveryFixed.value" v-bind="moneyFormat"></money>
                      </div>
                    </div>
                    <div class="col-2 box_check_frete" @click="setFreteGratisFixa(deliveryFixed)" style="line-height: 43px;">
                      <i class="fa check" v-bind:class="{ 'fa-check-square-o': deliveryFixed.frete, 'fa-square-o': !deliveryFixed.frete }"></i> Não cobrar frete
                    </div>
                    <div class="col-3"></div>
                    <div class="col-5 box_interno" style="border: 0;">
                      <input type="text" v-model="deliveryFixed.name" placeholder="Nome da Transportadora" class="form-control input background"/>
                    </div>
                    <div class="col-3"></div>
                  </div>
                </div>
              </div>
              <div class="" style="color:red; padding: 15px 0 0 10px;">
                O frete é de inteira responsabilidade do Vendedor!
              </div>
            </div>
          </div>
          <div class="modal-footer" style="justify-content: space-between;">
            <div class="box_total">
              <div v-if="paymentBilletSimulation.value_demand && checkPaymentChoices('BOLETO')">
                <div class="box_text">
                  TOTAL DA VENDA:
                </div>
                <div class="box_value">
                  R$ {{ floatToMoney(paymentBilletSimulation.value_total) }}
                </div>
              </div>
              <!-- <div v-if="paymentCardSimulations.length > 0 && checkPaymentChoices('CREDIT_CARD')">
                <div class="box_text">
                  TOTAL DA VENDA:
                </div>
                <div class="box_value">
                  R$ {{ floatToMoney(paymentCardSimulations[paymentCardPlot].value_total) }}
                </div>
              </div> -->
            </div>
            <div class="d-flex box_button pull-right" @click="send()">
              <div v-if="loading" class="spinner-grow text-light spinner-grow-sm" role="status" style="margin-right: 10px;"></div>
              <div class="box_icon">
                <i class="fa fa-usd"></i>
              </div>
              <div class="box_text" style="color:#fff;">
                CRIAR PROPOSTA
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL ACOMPANHAMENTO DA SOLICITAÇÃO -->
    <div class="modal fade" id="followProposalModal" tabindex="-1" role="dialog" aria-labelledby="followProposalModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex" style="align-items:center;padding: 16px;">
            <div class="col-5 padding_none">
              <h4 class="modal-title" id="detailsProposalModal" v-if="proposal">
              Acompanhe o andamento da proposta</h4>  
            </div>
            <div class="col-6 d-flex box_info" v-if="detailsSolicitation">
              <div class="box_icon">
                <img src="/img/button_pecas_color.png">
              </div>
              <div class="box_text">
                <span>Veículo: {{detailsSolicitation.vehicle}}</span></br>
                <span>Categoria: {{detailsSolicitation.category.name}}</span></br>
                Subcategorias: 
                <span v-for="(sub, index) in detailsSolicitation.subcategories" :key="index+sub.name">
                  {{sub.name}}
                  <span v-if="index+1 < detailsSolicitation.subcategories.length">/</span>
              </span></br>
                <span>Preferência:</span>
                <span v-if="detailsSolicitation.new">Novo</span>
              <span v-if="detailsSolicitation.semi_new">Seminovo</span>
              <span v-if="!detailsSolicitation.new && !detailsSolicitation.semi_new">Não especificado</span>
              </div>
            </div>
            <div class="col-1 box_close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="proposal = ''">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="modal-body row" v-if="proposal">
            <div class="col-12" style="padding: 0 10px;" v-for="(k, index) in proposal.keys" :key="index+k.key">
              <div class="col-7 row line_details">
                <div class="col-12 padding_none box_text">
                    Item: {{k.key}} - {{k.value}}
                </div>
                <div class="col-12 padding_none">
                  <div class="box_image" v-for="(i, index2) in k.images" :key="index2+i" src="i" :style="{ 'background-image': 'url(\''+i+'\')'}" data-toggle="modal" data-target="#previewImageModal" @click="imagePreview = i"></div>
                </div>
              </div>
            </div>
            <div class="col-12 line_detalhe_proposta">
              <div class="col-7 box_detalhe" style="margin-bottom: 40px; margin-top: 20px;">
                <div class="badge_name">
                  ORIGEM/DESTINO
                </div>
                <div class="badge_icon">
                  <i class="fa fa-map-signs"></i>
                </div>
                <div class="form-control input background" v-if="proposal.delivery">
                  {{ proposal.delivery[0].cep_origin }} / {{ proposal.delivery[0].cep_destiny }}
                  <div class="badge_check">
                    <i class="fa fa-check"></i>
                  </div>
                </div>
              </div>
              <div class="col-7 box_detalhe" v-if="proposal.charge">
                <div class="badge_name">
                  R$ {{proposal.value}}
                </div>
                <div class="badge_icon">
                  <i class="fa fa-usd"></i>
                </div>
                <div class="form-control input background">
                  <span v-if="proposal.charge.payment == 'BOLETO'">
                    <i class="fa fa-barcode"></i>
                    Boleto à vista
                  </span>
                  <span v-if="proposal.charge.payment == 'CREDIT_CARD'">
                    <i class="fa fa-credit-card"></i>
                    Cartão de Crédito:
                    {{proposal.credit_installments + 'x de R$ ' + floatToMoney(proposal.card_simulations[proposal.card_simulations.length -1].plots[proposal.card_simulations.length -1].value)
                    }}
                  </span>
                  <div class="badge_check">
                    <i class="fa fa-check"></i>
                  </div>
                </div>
                <a v-if="proposal.charge.payment == 'BOLETO'" :href="proposal.charge.invoice_url" target="_blank">
                  <div class="form-control input boleto">
                    VER BOLETO
                  </div>
                </a>
              </div>
            </div>
            <div class="col-12 padding_none">
              <div class="col-7 box_shortcut_chat">
                <a :href="'solicitation?solicitation_id=' + solicitation">
                  <div class="box_icon"><i class="fa fa-comments-o"></i></div>
                  <div class="box_text">Deseja enviar uma mensagem<br/>para o cliente?</div>
                </a>
              </div>
            </div>
            <div class="col-12 padding_none">
              <div class="col-12 box_subheader text-center">
                ACOMPANHE SEU PEDIDO
              </div>
            </div>
            <div class="col-12 box_legenda">
              <div class="box_interno">
                <div class="box_item">
                  <div class="box_cor"></div>
                  <div class="box_text">Aguardando</div>
                </div>
                <div class="box_item">
                  <div class="box_cor" style="background-color: #01fe03;"></div>
                  <div class="box_text">Confirmado</div>
                </div>
                <div class="box_item">
                  <div class="box_cor" style="background-color: #dc3545;"></div>
                  <div class="box_text">Cancelado</div>
                </div>
              </div>
            </div>
            <div class="col-12 box_acompanhamento">
              <div class="box_esquerdo" v-bind:class="{ 'background_confirmado' : proposal.status != 'Aguardando Pagamento', 'background_cancelado' : proposal.status == 'Recusada', }"></div>
              <div class="box_interno">
                <div class="box_status" v-bind:class="classStatusPedido('pagamento', proposal.status)">
                  <div class="box_icon">
                    <div class="box_circle">
                      <i class="fa fa-usd"></i>
                    </div>
                  </div>
                  <div class="box_text">
                    {{classStatusPedido('pagamento', proposal.status) ? 'Pago' : 'Pagamento'}}
                  </div>
                </div>
                <div class="box_status" v-bind:class="classStatusPedido('envio', proposal.status)">
                  <div class="box_icon">
                    <div class="box_circle">
                      <i class="fa fa-truck"></i>
                    </div>
                  </div>
                  <div class="box_text">
                    {{classStatusPedido('envio', proposal.status) ? 'Enviado' : 'Envio'}}
                  </div>
                </div>
                <div class="box_status" v-bind:class="classStatusPedido('concluído', proposal.status)">
                  <div class="box_icon">
                    <div class="box_circle">
                      <img v-if="!classStatusPedido('entrega', proposal.status)" src="/img/dolly.svg">
                      <img v-if="classStatusPedido('entrega', proposal.status)" src="/img/dolly-confirmado.svg">
                    </div>
                  </div>
                  <div class="box_text">
                    {{classStatusPedido('entrega', proposal.status) ? 'Entregue' : 'Entrega'}}
                  </div>
                </div>
                <div class="box_status" v-bind:class="classStatusPedido('concluído', proposal.status)">
                  <div class="box_icon">
                    <div class="box_circle">
                      <i class="fa fa-check"></i>
                    </div>
                  </div>
                  <div class="box_text">
                    Concluído
                  </div>
                </div>
                <!-- <div class="box_status" v-bind:class="classStatusPedido('recebido', proposal.status)">
                  <div class="box_icon">
                    <div class="box_circle">
                      <img v-if="!classStatusPedido('recebido', proposal.status)" src="/img/hand-holding.svg">
                      <img v-if="classStatusPedido('recebido', proposal.status)" src="/img/hand-holding-confirmado.svg">
                    </div>
                  </div>
                  <div class="box_text">
                    Pagamento Recebido
                  </div>
                </div> -->
              </div>
              <div class="box_direito" v-bind:class="{ 'background_confirmado' : proposal.status == 'Concluído' || proposal.status == 'Pagamento Parcial' || proposal.status == 'Pagamento Recebido'}"></div>
            </div>
            <div class="col-12 box_actions">
              <div class="text-center" v-if="proposal.tracking_code && proposal.status == 'Aguardando Entrega'">
                <div>
                  Código de Rastreamento:
                  <span class="font-weight-bold">{{proposal.tracking_code}}</span>
                </div>
                <div v-if="proposal.tracking_link">
                  Link:
                  <a class="font-weight-bold" :href="proposal.tracking_link" target="_blank">{{proposal.tracking_link}}</a>
                </div>
              </div class="text-center">
              <div class="col-12 padding_none" v-if="proposal.status === 'Aguardando Envio'">
                <input type="text" v-model="tracking_code" placeholder="Informe o código de rastreamento *" class="form-control input background"/>
                <input type="text" v-model="tracking_link" placeholder="Informe o link de rastreamento" class="form-control input background"/>
                <div class="col-12 padding_none d-flex justify-content-center">
                  <button class="form-control input" :disabled="loadingAction || !tracking_code" @click="confirmShipment()">
                    <span v-if="!loadingAction">CONFIRMAR ENVIO</span>
                    <div v-if="loadingAction" class="spinner-grow spinner-grow-sm" role="status"></div>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 text-center mb-2" v-if="proposal.charge">
              Recebido {{proposal.received_installment}} {{proposal.received_installment > 1 ? 'parcelas' : 'parcela'}} de {{proposal.charge.installments.length}}
              <div v-for="(item, index) in proposal.charge.installments" :key="index">
                <a :href="item.attachment" target="_blank" v-if="item.attachment">Comprovante {{index+1}}</a>
              </div>
            </div>
          </div>
          <div class="modal-footer" style="justify-content: center; padding: 15px 0;">
            <div class="box_publicidade">
              <span>PUBLICIDADE</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL SIMULADOR -->
    <div class="modal fade" id="simulatorModal" tabindex="-1" role="dialog" aria-labelledby="simulatorModalModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Simulador de Custos</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showSimulator = false">
              <span aria-hidden="true">&times;</span>
          </button>
          </div>
          <div class="modal-body" style="padding: 0;" v-if="showSimulator">
          <simulator
            :authorization="authorization"
            :user="user"
            :modal="true"
            :dataSimulator="dataSimulator"
            @dataSimulatorAtt="loadPaymentSimulation">
          </simulator>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL VIZUALIZAÇÃO DE IMAGEM -->
    <div class="modal fade" id="previewImageModal" tabindex="-1" role="dialog" aria-labelledby="previewImageModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="imagePreview = ''">
                <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center" v-if="imagePreview">
            <img class="image_preview" :src="imagePreview">
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
    <div v-if="contact && showAvaliation">
      <avaliation :proposal="proposal" :user="user" :contact="contact" :authorization="authorization"></avaliation>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import moment from 'moment';
  import _ from 'lodash';
  import io from 'socket.io-client';

  export default {
    props: ['user', 'authorization', 'solicitation', 'contact', 'contact_cep', 'new_solicitation'],
    data: function() {
      var tunnel = 'https://tunnel.d3t.com.br';
      var api = 'https://autoradar.d3t.com.br/api';

      if (window.location.hostname == 'localhost'){
        tunnel = 'http://172.20.0.1:5555';
        api = 'http://172.24.0.1/api';
      }

      return {
        loading: false,
        loadingProposals: true,
        loadingSearch: false,
        loadingAction: false,
        showAvaliation: false,
        proposals: [],
        detailsSolicitation: '',
        proposal: '',
        imagePreview: '',
        deliveryMethods: '',
        deliveryMethodsSearched: false,
        deliveryChoices: [],
        paymentChoices: ['BOLETO', 'CREDIT_CARD'],
        paymentCardPlot: 0,
        paymentCardSimulations: [],
        paymentBilletSimulation: {},
        tracking_code: '',
        tracking_link: '',
        moneyFormat: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          precision: 2,
          masked: true
        },
        deliveryFixed: {
          days: '',
          value: '',
          name: '',
        },
        dataSimulator: {},
        dataSimulatorAtt: {},
        showSimulator: false,
        data: {
          user_id: null,
          solicitation_id: this.solicitation,
          //solicitation_keys: keys,
          solicitation_keys: '',
          payment_credit: '',
          payment_billet: '',
          credit_installments: '',
          billet_installments: '',
          weight: '',
          height: '',
          width: '',
          length: '',
          value: '',
          transports: '',
        },
        arrayStatus: [
          {value: 'pendente', label: 'Pendente'},
          {value: 'recusada', label: 'Recusada'},
          {value: 1, label: 'Aguardando Pagamento'},
          {value: 2, label: 'Aguardando Envio'},
          {value: 3, label: 'Aguardando Entrega'},
          {value: 4, label: 'Concluído'},
          {value: 5, label: 'Pagamento Recebido'},
          {value: 6, label: 'Pagamento Parcial'},
        ],
        filter: {
          status: '',
          search: '',
        },
        listaGeral: false,
        socket: io(tunnel, {
          query: `domain=${api}&authorization=${this.user.app_token}`
        }),
        adresses: [],
        senderCepIndex: null,
      };
    },
    created() {
      this.listProposals();
      this.getSolicitation();
      if (!this.solicitation){
        this.listaGeral = true;
      } else {
        this.getAdresses();
      }
      console.log("this.user");
      console.log(this.user);
      if (this.contact){
        this.data.user_id = this.contact.id;
      }
      
      this.$on('dataSimulatorAtt', response => {
        this.detailsSolicitation.keys[response.index].value = response.value;        
        this.loadPaymentSimulation();
        
        this.detailsSolicitation.keys[response.index].active = true;
        $("#box_check_"+response.id).addClass('box_checked');
      });
    },
    mounted() {
      if (this.new_solicitation != undefined){
        this.newProposal();
      }
    },
    computed: {
      total (toFloat = false) {
        if (this.proposal){
          let result = 0;
          let { value, delivery } = this.proposal;

          if(value) {
            result = this.moneyToFloat(value);
          }

          if(delivery.length && delivery[this.deliveryChoices] && delivery[this.deliveryChoices].value) {
            result += this.moneyToFloat(delivery[this.deliveryChoices].value);
          }

          /*if(toFloat) {
            return result;
          }*/

          return this.floatToMoney(result);
        }
      },

      filteredProposals() {
        return this.proposals.filter(item => {
          return item.solicitation.vehicle.toLowerCase().includes(this.filter.search.toLowerCase())
        })
      }
    },
    methods: {
      resetArray () {
        this.loading = false;
        this.loadingProposals = true;
        this.loadingSearch = false;
        this.loadingAction = false;
        this.showAvaliation = false;
        this.detailsSolicitation = '';
        this.proposal = '';
        this.imagePreview = '';
        this.deliveryMethods = '';
        this.deliveryMethodsSearched = false;
        this.deliveryChoices = [];
        this.paymentCardPlot = 0;
        this.paymentCardSimulations = [];
        this.paymentBilletSimulation = {};
        this.tracking_code = '';
        this.tracking_link = '';        
        this.deliveryFixed = {
          days : '',
          value : '',
          name : '',
        };
        this.dataSimulator = {};
        this.dataSimulatorAtt = {};
        this.showSimulator = false;
        this.data = {
          user_id : null,
          solicitation_id : this.solicitation,
          solicitation_keys : '',
          payment_credit : '',
          payment_billet : '',
          credit_installments : '',
          billet_installments : '',
          weight : '',
          height : '',
          width : '',
          length : '',
          value : '',
          transports : '',
        };
        this.senderCepIndex = null;
      },

      listProposals () {
        console.log("listProposals");
        this.loadingProposals = true;
        var endpoint = '';
        if (this.solicitation){
          endpoint = '/api/proposal/'+this.solicitation;
        } else {
          endpoint = '/api/proposal';
        }
        if (this.filter.status){
          endpoint = endpoint + '?status=' + this.filter.status;
        }
        axios.get(endpoint, { headers: { Authorization: this.authorization } })
        .then(response => {
          console.log("listProposals");
          console.log(response.data);
          if (response.data.content){
            this.proposals = response.data.content;
          }
        })
        .catch(e => {
          var msg = 'Houve um erro ao buscar as propostas! Por favor, tente novamente.'
          this.$swal("Oops!", msg, "error")
          console.error(e)
        })
        .finally(() => {
          this.loadingProposals = false
        })
      },

      getSolicitation () {
        if (this.solicitation){
          this.loadingSolicitation = true;
          axios.get('/api/solicitations/'+this.solicitation+'/show', { headers: { Authorization: this.authorization } })
          .then(response => { 
            if (response.data.content){
              this.detailsSolicitation = response.data.content;
            }
          })
          .catch(e => {
            var msg = 'Houve um erro ao buscar detalhes da solicitação! Por favor, tente novamente.'
            this.$swal("Oops!", msg, "error")
            console.error(e)
          })
          .finally(() => {
            this.loadingSolicitation = false
          })
        }
      },

      getAdresses () {
        if (this.solicitation){
          axios.get('/api/user/adresses', { headers: { Authorization: this.authorization } })
          .then(response => { 
            console.log("getAdresses");
            console.log(response.data.content);
            if (response.data.content){
              this.adresses = response.data.content;
            }
          })
          .catch(e => {
            var msg = 'Houve um erro ao buscar os endereços! Por favor, tente novamente.'
            this.$swal("Oops!", msg, "error")
            console.error(e)
          })
          .finally(() => {
            
          })
        }
      },

      classStatus (status){
        if(status == "Pendente") {
          return 'pendente';
        }

        if(status == "Recusada") {
          return 'recusada';
        }

        return 'aceita';
      },

      classStatusPedido (local, status){
        if(local === "pagamento") {
          if(status === "Recusada") {
            return 'status_cancelado';
          }
          if(status === 'Aguardando Envio') {
            return 'status_confirmado end_gradient';
          }
          if(status === 'Aguardando Entrega' || status === 'Concluído' || status === 'Pagamento Recebido' || status === 'Pagamento Parcial') {
            return 'status_confirmado';
          }
          return '';
        }
        if(local === "envio") {
          if(status === 'Aguardando Entrega') {
            return 'status_confirmado end_gradient';
          }
          if(status === 'Concluído' || status === 'Pagamento Recebido' || status === 'Pagamento Parcial') {
            return 'status_confirmado';
          }
          return '';
        }
        if(local === "entrega") {
          if(status === 'Concluído' || status === 'Pagamento Recebido' || status === 'Pagamento Parcial') {
            return 'status_confirmado';
          }
          return '';
        }
        if(local === "concluído") {
          if(status === 'Concluído') {
            return 'status_confirmado';
          }
          if(status === 'Pagamento Recebido' || status === 'Pagamento Parcial') {
            return 'status_confirmado';
          }
          return '';
        }
        if(local === "recebido") {
          if(status === 'Pagamento Recebido' || status === 'Pagamento Parcial') {
            return 'status_confirmado';
          }
          return '';
        }
      },

      moneyToFloat (money) {
        if(!money || !money.length) {
          return false;
        }

        if(money.indexOf('R$') != -1) {
          money = money.split('R$')[1];
        }

        let toFloatNumber = '';

        for (let letter of money) {
          if(letter !== '.' && letter !== ',') {
            toFloatNumber += letter;
          }
        }

        let integerNumbers = '';
        for (let letter of toFloatNumber.substr(0, toFloatNumber.length - 2)) {
          if(letter !== ' ') {
            integerNumbers += letter;
          }
        }
        
        let decimalNumbers = toFloatNumber.substr(toFloatNumber.length - 2, toFloatNumber.length);
        
        return parseFloat(`${integerNumbers}.${decimalNumbers}`);
      },

      floatToMoney (money) {
        if(!money) {
          return false;
        }

        money = money.toLocaleString('pt-BR');

        if(money.indexOf('.') !== -1) {
          money = money.replace('.', ',');
        }

        if(money.indexOf(' ') !== -1) {
          money = money.replace(/ /g, '.');
        }

        if(money == '00') {
          money = '0,00';
        }

        return money;
      },

      newProposal(){
        this.getSolicitation();
        $('#newProposalModal').modal({
          show: true,
          keyboard: false,
          backdrop: 'static',
        })
      },

      modalSimulator(index, value, id){
        this.dataSimulator = {
          "index": index,
          "value": value,
          "id": id,
        }
        this.showSimulator = true;
        $('#simulatorModal').modal({
          show: true,
        })
      },

      modalStatusProposal(p){
        this.proposal = p;
        console.log("modalStatusProposal");
        console.log(p);
        if (!this.solicitation){
          this.solicitation = p.solicitation_id;
          this.getSolicitation();
        }
        if (p.status !== 'Pendente' && p.status !== 'Recusada'){
          $('#followProposalModal').modal({
            show: true,
          })
        } else {
          $('#detailsProposalModal').modal({
            show: true,
          })
        }
      },

      checkItem(id, index, value){
        if (!value){
          if (this.detailsSolicitation.keys[index].active){
            this.detailsSolicitation.keys[index].active = false;
            $("#box_check_"+id).removeClass('box_checked');
          } else {
            this.detailsSolicitation.keys[index].active = true;
            $("#box_check_"+id).addClass('box_checked');
          }
        } else {
          this.detailsSolicitation.keys[index].active = true;
          $("#box_check_"+id).addClass('box_checked');
        }
      },

      setCheckPaymentChoices(value) {
        const exists = this.paymentChoices.filter((a) => a !== value);

        if(exists.length === this.paymentChoices.length) {
          if (exists.length){
            this.paymentChoices = [];
            return this.paymentChoices = [exists[0], value];
          } else {
            return this.paymentChoices = [value];
          }
        }

        if(value === 'CREDIT_CARD') {
          this.paymentCardPlot = null;
        }

        return this.paymentChoices = exists;
      },

      checkPaymentChoices(value) {
        const exists = this.paymentChoices.filter((a) => a === value);

        if(!exists.length) {
          return false;
        }

        return true;
      },

      loadDeliveryMethods() {
        this.loadingSearch = true;

        var total = 0;
        this.detailsSolicitation.keys.forEach((value, index) => {
          if (this.moneyToFloat(value.value)){
            total += this.moneyToFloat(value.value);
          }
        });

        if(!total) {
          this.loadingSearch = false;
          return this.$swal("Oops!", 'Preencha o Valor do(s) item(s) antes de buscar as Transportadoras disponíveis.', "error");
        }

        if(!this.data.weight || !this.data.height || !this.data.width || !this.data.length) {
          this.loadingSearch = false;
          return this.$swal("Oops!", 'Preencha todos os campos de Frete antes de buscar.', "error");
        }

        if(this.senderCepIndex == null) {
          this.loadingSearch = false;
          return this.$swal("Oops!", 'Escolha o CEP do Remetente antes de buscar Transportadoras disponíveis.', "error");
        }

        let dados = {
          user_id: this.contact.id,
          weight: this.data.weight,
          height: this.data.height,
          width: this.data.width,
          length: this.data.length,
          value: total,
          cep_origin: this.adresses[this.senderCepIndex].cep,
          cep_destiny: this.contact_cep,
          quantity: 1
        }
        console.log("dados");
        console.log(dados);

        axios.post('/api/calc_melhor_envio', dados, { headers: { Authorization: this.authorization }})
        .then(response => {
          console.log("loadDeliveryMethods");
          console.log(response.data.content);

          const ShippingSevicesArray = response.data.content;

          if(!ShippingSevicesArray || !ShippingSevicesArray.length) {
            this.loadingSearch = false;
            return this.$swal("Oops!", 'Ocorreu um erro ao consultar as transportadoras.', "error");
          }
         
          let methods = ShippingSevicesArray.filter((a) => !a.error)

          methods.forEach((value, index) => {
            methods[index].ShippingPrice = this.floatToMoney(parseFloat(value.price));
            methods[index].Carrier = value.company.name;
            methods[index].OriginalDeliveryTime = value.delivery_time;
            methods[index].ServiceDescription = value.name;
          });
          
          this.loadingSearch = false;
          this.deliveryMethods = methods;
          this.deliveryMethodsSearched = true;
        })
        .catch(e => {
          var msg = 'Ocorreu um erro ao consultar as transportadoras.'
          this.$swal("Oops!", msg, "error")
          console.error(e)
        })
        .finally(() => {
          this.loadingSolicitation = false
        })

        // axios.post('/api/calc_frenet', dados, { headers: { Authorization: this.authorization }})
        // .then(response => {
        //   console.log("loadDeliveryMethods");
        //   console.log(response.data);

        //   //const { ShippingSevicesArray } = response.data.content;
        //   const ShippingSevicesArray = response.data.content.ShippingSevicesArray;

        //   if(!ShippingSevicesArray || !ShippingSevicesArray.length) {
        //     this.loadingSearch = false;
        //     return this.$swal("Oops!", 'Ocorreu um erro ao consultar as transportadoras.', "error");
        //   }
         
        //   let methods = ShippingSevicesArray.filter((a) => !a.Error && !a.Msg.length)

        //   methods.forEach(({ ShippingPrice }, index) => {
        //     methods[index].ShippingPrice = this.floatToMoney(parseFloat(ShippingPrice));
        //   });
          
        //   this.loadingSearch = false;
        //   this.deliveryMethods = methods;
        //   this.deliveryMethodsSearched = true;
        // })
        // .catch(e => {
        //   var msg = 'Ocorreu um erro ao consultar as transportadoras.'
        //   this.$swal("Oops!", msg, "error")
        //   console.error(e)
        // })
        // .finally(() => {
        //   this.loadingSolicitation = false
        // })
      },

      checkDeliveryChoices(value) {
        const exists = this.deliveryChoices.filter((a) => a === value);

        if(!exists.length) {
          return false;
        }

        return true;
      },

      setCheckDeliveryChoices(value) {
        const exists = this.deliveryChoices.filter((a) => a !== value);

        if(exists.length === this.deliveryChoices.length) {
          if (exists.length){
            this.deliveryChoices = [];
            exists.forEach((value, index) => {
              this.deliveryChoices.push(exists[index]);
            })
            this.deliveryChoices.push(value);
            return false;
          } else {
            return this.deliveryChoices = [value];
          }
        }

        return this.deliveryChoices = exists;
      },

      setFreteGratis(value, index) {
        if (value.ShippingPrice != 0){
          value.ShippingPriceDefault = value.ShippingPrice;
        }
        if (value.frete){
          console.log("1");
          value.frete = false;
        } else {
          console.log("2");
          value.frete = true;
        }
        if (value.frete){
          value.ShippingPrice = 0;
        } else {
          value.ShippingPrice = value.ShippingPriceDefault;
        }
      },

      setFreteGratisFixa(delivery) {
        console.log("delivery.value");
        console.log(delivery.value);
        if (this.moneyToFloat(delivery.value) != 0){
          delivery.valueDefault = this.moneyToFloat(delivery.value);
        }
        if (delivery.frete){
          delivery.frete = false;
        } else {
          delivery.frete = true;
        }
        if (delivery.frete){
          delivery.value = 0;
        } else {
          delivery.value = delivery.valueDefault;
        }
      },

      loadPaymentSimulation: _.debounce(function() {

        var money = 0;
        this.detailsSolicitation.keys.forEach((value, index) => {
          if (this.moneyToFloat(value.value)){
            money += this.moneyToFloat(value.value);
          }
        });

        if(!money) {
          return false;
        }

        axios.post('/api/simulation-value-asaas', { value: money }, { headers: { Authorization: this.authorization }})
        .then(response => {
          if (response.data.content.length){
            let content = response.data.content;
            

            let data = [];
            for(let item of content) {
              if(item.type === 'BOLETO') {
                this.paymentBilletSimulation = item;
              }

              if(item.type === 'CREDIT_CARD') {
                item.value_plots = this.floatToMoney(this.moneyToFloat(item.plots[0].value));
                data.push(item);
              }
            }            

            this.paymentCardSimulations = data;
          }
        })
        .catch(e => {
          var msg = 'Houve um erro ao simular o pagamento! Por favor, tente novamente.'
          this.$swal("Oops!", msg, "error")
          console.error(e)
        })
        .finally(() => {
          
        })
      }, 500),

      send () {
        let keys = this.detailsSolicitation.keys.filter(({ active, value}) => active);
        //let keys = Vue.util.extend({}, this.detailsSolicitation.keys.filter(({ active, value}) => active));
        //let keys = Object.assign({}, filter_keys);
        //let value = this.detailsSolicitation.keys.filter(({ active, value}) => value);
        //let keys = this.detailsSolicitation.keys.filter(({ active, value}) => active && value);

        if(!keys.length) {
          return this.$swal('Oops!', 'É necessário que no mínimo um item seja selecionado!', 'error')
        }

        /*if(!value.length) {
          return this.$swal('Oops!', 'É necessário preencher ao menos um campo de Valor.', 'error')
        }*/

        if(!this.data.weight || !this.data.height || !this.data.width || !this.data.length) {
          return this.$swal('Oops!', 'É necessário preencher todos os campos de Transporte.', 'error')
        }

        if(!this.deliveryChoices.length) {
          return this.$swal('Oops!', 'É necessário escolher ao menos um meio de transporte.', 'error')
        }

        if(!this.paymentCardSimulations.length) {
          return this.$swal('Oops!', 'Verifique os Métodos de Pagamento.', 'error')
        }

        const existsTranspFixa = this.deliveryChoices.filter((a) => a === this.deliveryMethods.length);
        if (existsTranspFixa.length){
          if(!this.deliveryFixed.days) {
            return this.$swal('Oops!', 'É necessário preencher os dias para entrega!', 'error')
          }
          if(!this.deliveryFixed.name) {
            return this.$swal('Oops!', 'É necessário preencher o nome da transportadora!', 'error')
          }
        }

        this.loading = true;

        var total = 0;
        var keys2 = [];
        keys.forEach((val, index) => {
          var money = this.moneyToFloat(val.value);
          keys2.push({
            id: val.id,
            value: money
          })
          total += money;
        });


        // Parse keys
        //keys.map((i) => i.value = this.moneyToFloat(i.value));

        /*let values = keys.map(({ value }) => this.moneyToFloat(value));
        let total = values.reduce((acc, cur) => acc + cur);*/
        
        // Parse the transports
        let transports = [];
        if(this.deliveryChoices.length) {
          this.deliveryChoices.forEach((i) => {
            if(i >= this.deliveryMethods.length) {
              const { name, value, days } = this.deliveryFixed;

              return transports.push({
                name: name,
                value: this.moneyToFloat(value),
                days: days
              });
            }

            const { Carrier, ServiceDescription, ShippingPrice, OriginalDeliveryTime } = this.deliveryMethods[i];
          
            return transports.push({
              name: `${Carrier} ${ServiceDescription}`,
              value: ShippingPrice,
              days: OriginalDeliveryTime || '0'
            });
          });
        }

        // Parse payment methods
        const payment_credit = this.checkPaymentChoices('CREDIT_CARD') ? 1 : 0;
        const payment_billet = this.checkPaymentChoices('BOLETO') ? 1 : 0;

        const credit_installments = this.paymentCardSimulations[this.paymentCardPlot].portion;
        const billet_installments = 1;

        const data = {
          user_id: this.contact.id,
          solicitation_id: this.solicitation,
          solicitation_keys: keys2,
          cep_origin: this.adresses[this.senderCepIndex].cep,
          cep_destiny: this.contact_cep,
          payment_credit,
          payment_billet,
          credit_installments,
          billet_installments,
          weight: this.data.weight,
          height: this.data.height,
          width: this.data.width,
          length: this.data.length,
          value: total,
          transports,
        };

        console.log("data");
        console.log(data);
        //return null;

        axios.post('/api/proposal', data, { headers: { Authorization: this.authorization }})
        .then(response => {
          console.log("response.data");
          console.log(response.data);
          /*this.loading = false;
          return null;*/

          //Object.assign(this.$data, this.$options.data());
          this.resetArray();
          this.listProposals();
          this.getSolicitation();
          this.$swal({
            title: "Sucesso!",
            text: "Sua proposta foi criada e encaminhada com sucesso.",
            imageUrl: "/img/sucesso.png",
            imageWidth: 200,
          });
          $('#newProposalModal').modal('hide');

          // ENVIAR MENSAGEM NO CHAT
          this.enviar_mensagem();
        })
        .catch(e => {
          this.$swal({
            title: "Oops!",
            text: e.content,
            imageUrl: "/img/ops.png",
            imageWidth: 200,
          });
          console.error(e);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        })
      },

      enviar_mensagem () {
        const data2 = {
          method: 'POST',
          endpoint: '/chat/new_message',
          
          content: {
            solicitation_id: this.solicitation,
            send_id: this.user.id,
            receive_id: this.contact.id,
            image: null,
            message: 'Criamos uma proposta para você.',
            created_at: moment().format('YYYY-MM-DD HH:mm'),
          }
        }
        this.socket.emit('event', data2);
      },

      confirmShipment(){
        this.$swal({
          title: "Atenção!",
          text: "Deseja confirmar o envio?",
          type: "warning",
          showCancelButton: true,        
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
        }).then(result => {
          if (result.value) {
            this.loadingAction = true;

            let dados = {
              id: this.proposal.id,
              tracking_code: this.tracking_code,
              tracking_link: this.tracking_link,
              status: 3,
            }

            axios.post('/api/proposal/'+this.proposal.id+'/update', dados, { headers: { Authorization: this.authorization }})
            .then(response => {
              console.log(response.data);
              if (response.data.content){
                this.proposal.status = 'Aguardando Entrega';
                this.proposal.tracking_code = this.tracking_code;
                this.tracking_code = '';
                this.$swal({
                  title: "Sucesso!",
                  text: "Envio confirmado.",
                  imageUrl: "/img/sucesso.png",
                  imageWidth: 200,
                });
                this.loadingAction = false;
                this.showAvaliation = true;
                this.listProposals();
              }

            })
            .catch(e => {
              var msg = 'Ocorreu um erro ao confirmar o envio.';
              //this.$swal("Oops!", msg, "error");
              this.$swal({
                title: "Oops!",
                text: msg,
                imageUrl: "/img/ops.png",
                imageWidth: 200,
              });
              console.error(e);
              this.loadingAction = false;
            })
            .finally(() => {
              this.loadingAction = false;
            })
          }
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  .box_filtros {
    .box_input {
      border-radius: 20px;
      color: #000;
      padding: 8px;
      font-size: 15px;
      /*height: 30px;*/
    }
  }
  .box_list_proposal {
    background-color: #fff;
    padding:0;

    .row{
      margin: 0;
    }

    .input {
      border-radius: 20px;
      text-align:center;
      border: 1px solid #bbbbbb;
      color: #000;
    }

    .background {
      background-color: #e6e6e6;
    }

    .background_green {
      background-color: #01fe03;
    }

    .padding_none {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    #detailsProposalModal, #newProposalModal, #followProposalModal{
      .modal-content{
        border: 0;
      }

      .modal-header{
        background-color: #333;
        color: #fff;
        padding: 16px 0;

        .box_close{
          padding: 0 16px;

          .close{
            color: #fff;
          }
        }

        .box_name {
          color: #000;
          background-color: #fff;
          border-bottom-right-radius: 30px;
          border-top-right-radius: 30px;
          padding: 5px 10px;
          font-size: 16px;
          width: 70%;
        }

        h4{
          padding: 0 10px;
        }

        .box_info{
          padding: 0;
          align-items: center;

          .box_icon{
            float: left;

            img{
              width: 40px;
              margin-right: 10px;
            }
          }
          .box_text{
            float: left;
            font-size: 14px;
            line-height: 1.2;

            span {
              line-height: 1.2;
            }
          }
        }
      }

      .modal-footer{
        background-color: #333;
        padding: 30px 0;
        justify-content: flex-start;

        .box_total{
          padding: 0;
          margin: 0;
          line-height: 1.2;

          .box_text {
            color: #fff;
            font-size: 14px;
            padding: 0 10px;
          }

          .box_value {
            color: #000;
            background-color: #fff;
            border-bottom-right-radius: 30px;
            border-top-right-radius: 30px;
            width: 150px;
            text-align: center;
            padding: 2px;
            font-size: 16px;
          }
        }

        .box_publicidade{
          width: 400px;
          background-color: #fff;
          text-align: center;
          border-radius: 5px;
          height: 70px;
          align-items: center;
          display: flex;
          justify-content: center;
        }
      }

      .modal-body{
        padding: 0;

        .box_subheader{
          background-color: #333;
          color: #fff;
          padding: 10px;
          margin-top: 10px;
        }

        .line_transport{
          color: #000;
          font-size: 14px;
          padding: 10px;

          .box_interno {
            border-bottom: 1px solid #333;
            padding: 10px;

            .box_text{
              padding: 0;
            }

            .box_value{
              padding: 0;
              border-radius: 15px;
              background-color: #e7e7e7;
              text-align: center;
            }

            .box_value_checked {
              background-color: #01fe03;
            }

            .box_check_frete{
              padding: 0;
              text-align: left;
              font-size: 13px;
              text-align: center;
              cursor: pointer;

              .fa-check-square-o {
                color: #01fe03;
              }
            }
          }

          .box_check {
            width: 35px;
            border-radius: 15px;
            background-color: #e6e6e6;
            text-align: center;
            font-size: 20px;
            margin-right: 10px;
            height: 20px;

            i{
              display: none;
            }
          }
          .box_checked {
            background-color: #01fe03;
            color: #fff;

            i {
              display: block;
            }
          }
        }

        .box_input_tarifa {
          border-radius: 20px;
          color: #000;
          padding: 5px;
          font-size: 13px;
          height: 30px;
        }

        .box_tarifa {
          border-radius: 20px;
          color: #000;
          padding: 0;
          height: 30px;
          margin-bottom: 5px;
          background-color: #e6e6e6;

          .icon {
            background-color: #f8f8f8;
            border: 1px solid #a3a3a3;
            height: 30px;
            width: 40px;
            border-radius: 15px;
            line-height: 30px;
            text-align: center;
            float: left;
          }

          .check {
            float:right;
            line-height: 30px;
            margin-right: 20px;
          }

          .fa-check-square-o {
            color: #01fe03;
          }

          span{
            line-height: 30px;
            padding-left: 5px;
          }
        }

        .line_details{
          padding: 10px;
          border-bottom: 1px solid #333;
          margin-bottom: 5px;

          .box_text{
            color: #000;
            font-size: 13px;
            margin-bottom: 5px;
            padding: 0;
            float: left;

            .box_check {
              width: 35px;
              border-radius: 15px;
              background-color: #e6e6e6;
              text-align: center;
              font-size: 20px;
              margin-right: 10px;
              height: 20px;

              i{
                display: none;
              }
            }
            .box_checked {
              background-color: #01fe03;
              color: #fff;

              i {
                display: block;
              }
            }
          }

          .box_image{
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            border: 1px solid #333;
            background-color: #fff;
            margin: 0 5px;
            box-shadow: 1px 1px 3px #ccc;
            float:left;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }

          .box_fields {
            padding: 15px 0 10px 0;

            .box_input {
              padding: 0;
              padding-right: 10px;

              .input {
                border-radius: 20px;
                text-align:center;
                border: 1px solid #bbbbbb;
                color: #000;
              }
            }

            .box_simulator {
              padding: 0;
              padding-right: 10px;
              cursor: pointer;

              .input {
                border-radius: 20px;
                text-align:center;
                border: 1px solid #01fe03;
                color: #000;
                background-color: #01fe03;
              }
            }
          }
        }

        .box_frete{
          padding: 10px;

          .box_fields {
            padding: 0;
            padding-top: 15px;
            margin-top: 15px;

            border-top: 1px solid #333;

            .box_interno{
              padding: 0;

              .box_text{
                color: #000;
                font-size: 13px;
                text-align: center;
                padding-right: 10px;
              }

              .box_input {
                padding: 0;
                padding-right: 10px;
                margin-bottom: 5px;

                .input {
                  border-radius: 20px;
                  text-align:center;
                  border: 1px solid #bbbbbb;
                  color: #000;
                }
              }
            }
          }

          .box_button_busca {
            padding: 15px 0;
            justify-content: center;

            .btn {
              border-radius: 20px;
              text-align:center;
              border: 1px solid #01fe03;
              background-color: #01fe03;
              color: #000;
              width: 200px;
            }
          }
        }

        .line_detalhe_proposta{
          padding: 30px;
          padding-bottom: 20px;

          .box_detalhe{
            padding: 0;

            .badge_name {
              background-color: #333;
              color: #fff;
              border-radius: 35px;
              text-align: center;
              position: absolute;
              top: -20px;
              left: 8px;
              font-size: 13px;
              padding: 1px 18px;
            }

            .badge_icon {
              background-color: #FF8533;
              color: #fff;
              padding: 0;
              border-radius: 100%;
              text-align: center;
              position: absolute;
              top: -25px;
              left: -15px;
              font-size: 18px;
              height: 35px;
              width: 35px;
              line-height: 35px;
            }

            .input{
              border: 1px solid #333;
            }

            .badge_check {
              width: 50px;
              height: 36px;
              border-radius: 30px;
              background-color: #01fe03;
              color: #fff;
              text-align: center;
              font-size: 26px;
              position: absolute;
              right: 1px;
              top: 1px;
              line-height: 38px;
            }

            .boleto{
              border: 1px solid #01fe03;
              color: #fff;
              background-color: #01fe03;
              width: 150px;
              margin: 0 auto;
              margin-top: 10px;
            }
          }
        }

        .box_shortcut_chat{
          display: inline-flex;
          align-items: center;
          padding: 0 30px;
          margin-bottom: 20px;

          a{
            display: inline-flex;
            color: #000;
          }

          .box_icon{
            width: 45px;
            height: 45px;
            line-height: 45px;
            background-color: #FF8533;
            color: #fff;
            padding: 0;
            border-radius: 100%;
            text-align: center;
            font-size: 28px;
            margin-right: 10px;
          }

          .box_text{
            font-size: 13px;
            line-height: 1.3;
          }
        }

        .box_acompanhamento{
          color: #000;
          font-size: 14px;
          padding: 20px 0 10px 0;
          display: flex;
          justify-content: center;
          align-items: center;

          .box_interno{

            .box_status{
              padding: 0;
              float: left;
              display: grid;

              .box_icon {
                display: flex;
                align-items: center;
                width: 120px;

                .box_circle {
                  width: 60px;
                  height: 60px;
                  border: 5px solid #000;
                  border-radius: 100%;
                  float: left;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 28px;
                  margin: 0 auto;

                  .fa-truck{
                    -moz-transform: scaleX(-1);
                    -o-transform: scaleX(-1);
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                  }

                  img{
                    width: 28px;
                  }
                }
              }

              .box_icon:after {
                position: relative;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                height: 5px;
                content: "";
                background-color: #000;
              }

              .box_icon:before {
                position: relative;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                height: 5px;
                content: "";
                background-color: #000;
              }

              .box_text{
                width: 100%;
                float: left;
                text-align:center;
                padding: 0;
                padding-top: 5px;
                font-size: 13px;
              }
            }

            .status_confirmado {

              .box_circle {
                border-color: #01fe03 !important;
              }

              i {
                color: #01fe03 !important;
              }

              .box_icon:after {
                background-color: #01fe03;
              }

              .box_icon:before {
                background-color: #01fe03;
              }
            }

            .end_gradient {
              .box_icon:after {
                background: linear-gradient(90deg, rgba(1,254,3,1) 0%, rgba(0,0,0,1) 100%) !important;
              }
            }

            .status_first_confirmado {
              .box_icon:before {
                background-color: #01fe03;
              }
            }

            .status_cancelado {

              .box_circle {
                border-color: #dc3545 !important;
              }

              i {
                color: #dc3545 !important;
              }

              .box_icon:after {
                background: linear-gradient(90deg, rgba(220,53,69,1) 0%, rgba(0,0,0,1) 100%) !important;
              }

              .box_icon:before {
                background-color: #dc3545;
              }
            }

          }

          .box_esquerdo {
            position: relative;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            height: 5px;
            content: "";
            background-color: #000;
            margin-bottom: 24px;
          }

          .box_direito {
            position: relative;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            height: 5px;
            content: "";
            background-color: #000;
            margin-bottom: 24px;
          }

          .background_confirmado {
            background-color: #01fe03;
          }

          .background_cancelado {
            background-color: #dc3545;
          }

        }

        .box_legenda {
          padding: 0;
          padding-top: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          .box_interno{

            .box_item{
              display: inline-flex;
              align-items: center;

              .box_cor{
                border-radius: 100%;
                width:10px;
                height:10px;
                margin-right: 5px;
                background-color: #000;
                box-shadow: 1px 1px 3px #ccc;
              }

              .box_text{
                color: #000;
                font-size: 12px;
                margin-right: 20px;
              }
            }
          }
        }

        .box_actions{
          padding: 15px 0 20px 0;
          display: grid;
          justify-content: center;
          align-items: center;

          input{
            margin-bottom: 10px;
            width: 300px;
          }

          button{
            border: 1px solid #01fe03;
            color: #fff;
            background-color: #01fe03;
            width: 170px;
            cursor: pointer;            
          }
        }
      }
    }

    .box_text {
      padding: 5px 0;
    }

    .box_header {
      background-color: #333;
      color: #fff;
      padding: 20px;
      padding-left: 25px;
      align-items:center;
    }

    .box_loading{
      padding: 50px 0;
    }

    .box_proposals {
      padding: 30px;

      .box {
        background-color: #333;
        color: #fff;
        padding: 15px;
        border-radius: 30px;
        margin-bottom: 25px;
        text-align: center;
        cursor: pointer;
        border: 0;
      }

      .badge_name{
        background-color: #e7e7e7;
        color: #000;
        padding: 0;
        border-radius: 30px;
        text-align: center;
        width: 100px;
        position: absolute;
        top: -10px;
        left: 30px;
        font-size: 14px;
      }

      .badge_status{
        color: #fff;
        padding: 0;
        border-radius: 30px;
        text-align: center;
        position: absolute;
        top: -10px;
        right: 30px;
        font-size: 14px;
        padding: 0 10px;
      }

      .pendente{
        background-color: #FF8533;
      }

      .recusada{
        background-color: #dc3545;
      }

      .aceita{
        background-color: #01fe03;
        color: #000;
      }
    }

    .box_button{
      padding: 0;
      margin: 20px;
      align-items: center;
      cursor:pointer;

      .box_icon{
        float: left;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        line-height: 40px;
        font-size: 28px;
        border-radius: 100%;
        background-color: #ff8533;
        color: #fff;
        text-align:center;
      }
      .box_text{
        float: left;
        font-size: 14px;
        line-height: 1.2;

        span {
          line-height: 1.2;
        }
      }
    }
  }
</style>
