<template>
	<div class="ocupations">
		<div :class="loading ? 'loading loading_active' : 'loading'">
			<img src="/img/logo-mini.png" alt="AutoRadar">
		</div>
		<div class="categories">
			<div :class="category.id == category_selected ? 'categories__box categories__box_selected' : 'categories__box'"
				v-for="(category, index) in categories"
				:key="index"
				v-if="category.father_id == null"
				@click="get_subcategories(category.id)">
				<img v-if="category.icon" :src="category.icon" alt="Icone da Subcategoria">
				<i v-else class="fa fa-briefcase" aria-hidden="true"></i>
				<p>{{category.name}}</p>
			</div>
		</div>

		<hr class="w-100" v-if="category_selected != null">
		<div class="tags row d-flex justify-content-center" v-if="category_selected != null">
			<div class="col-12">
				<h3 class="text-center">Escolha suas Tags</h3>
				<p class="text-center">Selecione as Tags para filtrar o que você <b>NÃO</b> deseja receber como mensagens</p>
			</div>
			<div class="categories d-flex flex-column pl-3">
				<div class="show">
					<p class="m-0" @click="tags_show = !tags_show">{{tags_show ? 'Ocultar Tags' : 'Mostrar Tags'}}</p>
				</div>
				<div class="row" v-if="tags_show">
					<div class="col-md-12 check_search">
						<input class="search_brands" type="text" placeholder="Pesquise uma tag" v-model="search_tags">
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-md-6 px-1"
				v-for="(tag, index) in filter_tags"
				:key="index"
				v-if="tag.category_id == category_selected && tags_show">
				<div class="tags__itens">
					<input type="checkbox" name="tag" :id="`tag_`+tag.id" value="true" :checked="tag.providing" @click="check_tag(tag)"> <label :for="`tag_`+tag.id">{{tag.name}}</label>
				</div>
			</div>
		</div>

		<hr class="w-100" v-if="category_selected != null">
		<div class="categories d-flex flex-column" v-if="category_selected != null">
			<div class="row">
				<div class="col-12">
					<h3 class="text-center">Escolha o que você deseja vender</h3>
				</div>
			</div>
			<div class="d-flex justify-content-center">
				<div :class="subcategory.providing && subcategory_selected == subcategory.id ? 'categories__box categories__box_active categories__box_selected' :
											subcategory.providing ? 'categories__box categories__box_active' :
											subcategory_selected == subcategory.id ? 'categories__box categories__box_selected' : 'categories__box'"
					v-for="(subcategory, index) in types"
					:key="index"
					v-if="types != null"
					@click="get_brand(subcategory.id)">
					<img v-if="subcategory.icon" :src="subcategory.icon" alt="Icone da Subcategoria">
					<i v-else class="fa fa-briefcase" aria-hidden="true"></i>
					<p>{{subcategory.name}}</p>
				</div>
			</div>
		</div>

		<hr class="w-100" v-if="subcategory_selected != null">
		<div class="categories flex-column" v-if="subcategory_selected != null">
			<div class="row">
				<div class="col-12">
					<h3 class="text-center">Escolha as marcas que você deseja vender</h3>
				</div>
			</div>
			<div class="show">
				<p @click="brands_show = !brands_show">{{brands_show ? 'Ocultar Marcas' : 'Mostrar Marcas'}}</p>
			</div>
			<div class="row" v-if="brands_show">
				<div class="col-md-12 check_search">
					<div class="mb-2 mr-3">
						<input type="checkbox" name="check_all" id="check_all" value="true" @click="check_all_brands()"> <label for="check_all">Marcar Todas</label>
					</div>
					<input class="search_brands" type="text" placeholder="Pesquise uma marca" v-model="search_brands">
				</div>
				<div class="col-xl-3 col-sm-6 px-1"
					v-for="(brand, index) in filter_brands"
					:key="index"
					v-if="brands != null">
					<div class="tags__itens">
						<input type="checkbox" name="brand" :id="`brand_`+brand.id" value="true" :checked="brand.providing" @click="check_brand(brand)"> <label :for="`brand_`+brand.id">{{brand.name}}</label>
					</div>
				</div>
			</div>
		</div>

		<hr class="w-100" v-if="subcategory_selected != null && subcategorias.length != 0">
		<div class="categories row" v-if="subcategory_selected && subcategorias.length != 0">
			<div class="col-12">
				<h3 class="text-center">Escolha as subcategorias que você deseja vender</h3>
			</div>
		</div>
		<div class="categories row" v-for="(items, index) in subcategorias" :key="index" v-if="subcategorias != null">
			<div :class="item.providing ? 'categories__box categories__box_active' : 'categories__box'"
					 :id="`subcategory_${item.id}`"
					 v-for="(item, index2) in items"
					 :key="index2"
					 @click="next_page(item, index)">
				<img v-if="item.icon" :src="item.icon" alt="Icone da Subcategoria">
				<i v-else class="fa fa-briefcase" aria-hidden="true"></i>
				<p>{{item.name}}</p>
			</div>
		</div>
		<div class="save" v-if="subcategory_selected != null">
			<p @click="save">SALVAR</p>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		props: ['categories', 'tags', 'authorization'],
		data: function() {
			return {
				category_selected: null,
				subcategory_selected: null,
				loading: false,
				search_brands: null,
				search_tags: null,
				tags_show: 1,
				brands_show: 1,
				check_all: false,
				types: [],
				subcategorias: [],
				brands: [],
				form: {
					category_id: null,
					tags: [],
					subcategories: []
				}
			};
		},
		computed: {
			filter_brands() {
				if (this.search_brands) {
					return this.brands.filter(item => item.name.toUpperCase().indexOf(this.search_brands.toUpperCase()) > -1)
				} else {
					return this.brands
				}
			},
			filter_tags() {
				if (this.search_tags) {
					return this.tags.filter(item => item.name.toUpperCase().indexOf(this.search_tags.toUpperCase()) > -1)
				} else {
					return this.tags
				}
			}
		},
		methods: {
			check_brand (brand) {
				if (this.form.subcategories.filter(item => item.id == brand.id).length > 0) {
					this.form.subcategories = this.form.subcategories.filter(item => item.id !== brand.id)
				} else {
					this.form.subcategories.push({'id':brand.id})
				}
			},

			check_all_brands () {
				if (this.check_all) {
					this.brands.forEach(brand => {
						this.form.subcategories = this.form.subcategories.filter(item => item.id !== brand.id)
						document.getElementById(`brand_${brand.id}`).checked = false;
					});
				} else {
					this.form.subcategories = []
					this.brands
					this.brands.forEach(brand => {
						this.form.subcategories.push({'id':brand.id})
						document.getElementById(`brand_${brand.id}`).checked = true;
					});
				}
				this.check_all = !this.check_all
			},

			check_tag (tag) {
				if (this.form.tags.filter(item => item.id == tag.id).length > 0) {
					this.form.tags = this.form.tags.filter(item => item.id !== tag.id)
				} else {
					this.form.tags.push({'id':tag.id})
				}
			},

			check_subcategory (subcategory) {
				if (this.form.subcategories.filter(item => item.id == subcategory.id).length > 0) {
					this.form.subcategories = this.form.subcategories.filter(item => item.id !== subcategory.id)
				} else {
					this.form.subcategories.push({'id':subcategory.id})
				}
			},

			drop_pages_selected (index) {
				// Se o item foi desmarcado é preciso passar por todos os itens, a partir do index do item (index + 1)
				// removendo do form (usado para salvar no banco)
				// e remover da subcategorias (usado para listagem da tela)
				for (let i = index+1; i < this.subcategorias.length; i++) {
					this.subcategorias[i].forEach(subcategoria => {
						if (this.form.subcategories.filter(item => item.id == subcategoria.id).length > 0 || subcategoria.providing) {
							this.check_subcategory(subcategoria)
						}
						subcategoria.providing = false
					})
				}
				this.subcategorias.splice((index+1), (this.subcategorias.length - 1 - index))
			},

			drop_pages (item, index) {
				// Se o item foi marcado é preciso remover os itens de outras categorias abertos
				// e mostrar o filhos a partir dela
				// também é preciso remover a classe de selecionado das classes do mesmo nivel

				for (let i = index; i < this.subcategorias.length; i++) {
					if (i == index) {
						this.subcategorias[i].forEach(subcategoria => {
							if (subcategoria.id != item.id) {
								document.getElementById(`subcategory_${subcategoria.id}`).classList.remove('categories__box_selected')
							}
						})
					} else {
						this.subcategorias[i].forEach(subcategoria => {
							if (subcategoria.providing) {
								document.getElementById(`subcategory_${subcategoria.id}`).classList.remove('categories__box_selected')
							} else {
								if (this.form.subcategories.filter(s => s.id == subcategoria.id).length > 0) {
									subcategoria.providing = true
									document.getElementById(`subcategory_${subcategoria.id}`).classList.remove('categories__box_active');
									document.getElementById(`subcategory_${subcategoria.id}`).classList.remove('categories__box_selected')
								}
							}
						})
					}
				}

				this.subcategorias.splice((index+1), (this.subcategorias.length - 1 - index))
				if (item.childs.length > 0) {
					this.subcategorias.push(item.childs)
				}
			},

			next_page (item, index) {
				if (document.getElementById(`subcategory_${item.id}`).classList == 'categories__box categories__box_active categories__box_selected') {
					// Limpar filhos do item desmarcado
					this.drop_pages_selected(index)
					document.getElementById(`subcategory_${item.id}`).classList.remove('categories__box_active');
					document.getElementById(`subcategory_${item.id}`).classList.remove('categories__box_selected');
					this.check_subcategory(item)
				} else {
					// Se o item marcado ainda não esta no form precisamos adicionar
					if (this.form.subcategories.filter(subcategory => subcategory.id == item.id).length == 0) {
						this.form.subcategories.push({'id':item.id})
					}
					// Remover outras categorias da listagem e mostrar as categoria derivadas da marcada
					this.drop_pages(item, index)
					document.getElementById(`subcategory_${item.id}`).classList.add('categories__box_active');
					document.getElementById(`subcategory_${item.id}`).classList.add('categories__box_selected');
				}
			},

			reset() {
				this.subcategory_selected = null,
				this.tags_show = 1,
				this.brands_show = 1,
				this.check_all = false,
				this.form = {
					category_id: null,
					tags: [],
					brands: [],
					subcategories: []
				}
			},

			get_subcategories (category) {
				this.reset()
				this.loading = true
				this.category_selected = category
				this.subcategorias = []
				this.types = []

				this.tags.forEach(tag => {
					if (tag.providing) {
						this.form.tags.push({'id':tag.id})
					}
				});

				axios.get(`/api/solicitations/category/${category}/childs`,
								 { headers: { Authorization: this.authorization } })
				.then(response => {
					this.types = response.data.content
				})
				.catch(e => {
					var msg = 'Houve um erro! Por favor, tente novamente.'
					this.$swal("Oops!", msg, "error")
					console.error(e.response)
				})
				.finally(() => {
					this.loading = false
				})
			},

			get_brand (category) {
				this.loading = true
				// Limpamos todos os possiveis dados marcados
				// Se alguma marca selecionada linpa o checkbox para não ficar marcada indevidamente
				if (this.form.subcategories.length > 0 && this.brands_show == 1) {
					document.getElementById('check_all').checked = false;
					this.check_all = true,
					this.check_all_brands()
				}
				this.form.subcategories = []
				this.subcategorias = []
				this.brands_show= 1

				this.subcategory_selected = category
				axios.get(`/api/solicitations/category/${category}/subcategories/brands`,
								 { headers: { Authorization: this.authorization } })
				.then(response => {
					this.brands = response.data.content

					// Adicionar ao form os dados salvos no banco
					this.brands.forEach(brand => {
						if (brand.providing) {
							this.form.subcategories.push({'id':brand.id})
						}
					});
				})
				.catch(e => {
					var msg = 'Houve um erro! Por favor, tente novamente.'
					this.$swal("Oops!", msg, "error")
										console.error(e.response)

				})
				.finally(() => {
					this.loading = false
				})

				axios.get(`/api/solicitations/category/${category}/subcategories`,
								 { headers: { Authorization: this.authorization } })
				.then(response => {
					if (response.data.content.length != 0) {
						this.subcategorias.push(response.data.content)

						// Adicionar ao form os dados salvos no banco
						this.subcategorias.forEach(subcategoria => {
							this.openCreate(subcategoria)
						});
					}
				})
				.catch(e => {
					var msg = 'Houve um erro! Por favor, tente novamente.'
					this.$swal("Oops!", msg, "error")
										console.error(e.response)

				})
				.finally(() => {
					this.loading = false
				})
			},

			openCreate (subcategories) {
				subcategories.forEach(child => {
					if (child.providing == true && child.childs.length > 0) {
						this.form.subcategories.push({'id':child.id})
						this.openCreate(child.childs)
					} else {
						if (child.providing == true) {
							this.form.subcategories.push({'id':child.id})
						}
					}
				});
			},

			save() {
				this.loading = true
				this.form.category_id = this.subcategory_selected

				axios.post(`/api/occupation`, this.form,
								 { headers: { Authorization: this.authorization } })
				.then(response => {
					var msg = 'Dados atualizados com sucesso.'
					this.$swal("Sucesso!", msg, "success")
				})
				.catch(e => {
					var msg = 'Houve um erro! Por favor, tente novamente.'
					this.$swal("Oops!", msg, "error")
										console.error(e.response)

				})
				.finally(() => {
					this.loading = false
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
.ocupations {
	width: 100%;
	.loading {
		width: 100%;
		height: 100vh;
		position: fixed;
		z-index: 999;
		display: none;
		align-items: center;
		background: #a7855859;
		transition: 0.3s all;
		&_active {
			transition: 0.3s all;
			display: flex;
			justify-content: center;
		}
	}
	.categories{ 
		width: 100%;
		display: flex;
		justify-content: center;
		h3 {
			font-weight: bold;
			font-size: 22px;
			margin: 5px;
		}
		.check_search {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			.search_brands {
				padding: 3px 10px;
				margin: 0 0 10px;
				border-radius: 5px;
				border: 1px solid #c7790d;
				min-width: 300px;
			}
			@media (max-width: 575px) {
				flex-direction: column;
			}
		}
		.show {
			border-bottom: 5px solid #c56e0d;
			margin: 10px 0 5px;
			p {
				background: #c56e0d;
				padding: 5px;
				width: max-content;
				min-width: 150px;
				text-align: center;
				font-weight: bold;
				color: white;
				cursor: pointer;
			}
		}
		&__box {
			background: white;
			padding: 20px 10px;
			justify-content: space-between;
			display: flex;
			flex-direction: column;
			align-items: center;
			min-width: 120px;
    	min-height: 105px;
			margin: 15px;
			border-radius: 15px;
			transition: 0.3s all;
			cursor: pointer;
			i {
				font-size: 44px;
				color: rgba(197, 110, 13);
			}
			p {
				font-weight: bold;
    		margin-top: 5px;
			}
			img {
				max-width:50px;
				max-height:50px;
			}
			&:hover {
				transition: 0.3s all;
				background: #eaeaea;
				color: rgba(197, 110, 13, 0.5);
			}
			&_selected {
				transition: 0.3s all;
				background: #eaeaea;
				color: rgba(197, 110, 13, 0.5);
			}
			&_active {
				position: relative;
				border: 1px solid green;
				&:before {
					content: "Ativo";
					background: green;
					position: absolute;
					height: max-content;
					width: max-content;
					top: -10px;
					right: 10px;
					z-index: 9;
					color: white;
					font-weight: bold;
					padding: 0 5px;
					border-radius: 5px;
				}
			}
		}
	}
	.tags {
		margin-top: 15px;
		p {
			margin: 10px;
		}
		h3 {
			font-weight: bold;
			font-size: 22px;
			margin: 5px;
		}
		&__itens{
			display: flex;
    	align-items: center;
			background: #FFF;
			padding: 5px 15px;
			border-radius: 5px;
			margin: 5px 10px;
			label {
				width: calc(100% - 25px);
			}
		}
	}
	.save {
		border-top: 5px solid green;
		display: flex;
		justify-content: flex-end;
		margin-top: 10px;
		p {
			padding: 5px 15px;
			background: green;
			color: white;
			font-weight: bold;
			cursor: pointer;
		}
	}
	@media (max-width: 575px) {
		.categories {
			flex-direction: column;
			&__box {
				margin: 10px;
			}
			.search_brands {
				min-width: 100%;
			}
		}
	}
}
</style>