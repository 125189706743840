<template>
	<div class="col-12 mt-3">
    <div v-for="(item, index) in data" :key="index">
      <div class="box-container" v-if="!item.deleted">
        <input type="hidden" v-model="item.id">
        <div class="form-group row">
          <div class="col-sm-12">
            <div class="row">
              <label class="col-sm-12 control-label">Status*</label>
              <div class="col-sm-12">
                <input
                  type="checkbox"
                  id="status"
                  maxlength="255"
                  v-model="item.status"
                  checked
                >
                <label :for="'status_' + index">Ativo</label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12">
            <div class="row" v-if="subcategories && subcategories[index] && subcategories[index].fipe_brand_id">
              <label for="name" class="col-sm-12 control-label">Nome*</label>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  maxlength="200"
                  v-model="item.name"
                  required
                  disabled
                >
                <small class="form-text text-muted">Nome de Marca reservada pela tabela FIPE.</small>
              </div>
            </div>
            <div class="row" v-else>
              <label for="name" class="col-sm-12 control-label">Nome*</label>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  maxlength="200"
                  v-model="item.name"
                  required
                >
              </div>
            </div>
          </div>
        </div>     
        <div class="form-group row">
          <div class="col-sm-12">
            <div class="row">
              <label class="col-sm-12 control-label">Ícone</label>
              <div class="col-md-3 col-lg-2" v-if="subcategories && subcategories[index] && subcategories[index].icon">
                <img :src="subcategories[index].icon" style="width: 150px; margin-top: 5px; margin-bottom: 15px;">
              </div>
              <div class="col-sm-12">
                <input
                  type="file"
                  :name="'icon_' + index"
                >
                <small class="form-text text-muted">Tamanho recomendável: 128x128px.</small>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <button
            @click="remove(index)"
            type="button"
            class="btn btn-danger btn-flat ctaButton ml-3"
          >
            Remover
          </button>
          <a
            v-if="url && item.id != 0"
            :href="url + '/' + item.id + '/edit'"
            class="btn btn-primary btn-flat ctaButton ml-3"
          >
            Editar
          </a>
        </div>
      </div>
    </div>
    <div>
      <button
        @click="add"
        type="button"
        class="btn btn-info btn-flat ctaButton"
      >
        Adicionar Nova
      </button>
  	</div>
    <div>
      <input type="hidden" id="subcategories" name="subcategories" :value="JSON.stringify(this.data)">
  	</div>
	</div>
</template>

<script>
  export default {
		props: ['url', 'subcategories'],
		data: function() {
			return {
				data: [],
			};
    },
    created() {
      if (this.subcategories) {
        this.subcategories.map(({ id, name, status, deleted }, index) => {
          this.data.push({ id, name, status, deleted });
        })
      }
    },
    methods: {
      add: function() {
        this.data.push({
          id: 0,
          name: '',
          status: '1',
          deleted: false
        });
      },
      remove: function(index) {
        this.data.forEach((a, i) => {
          if(index === i) {
            a.deleted = true;
          }
        });
      }
    }
  }
</script>
<style lang="scss" scoped>
.box-container {
  padding: 15px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin-bottom: 30px;
}

.btn-info {
  background-color: #c56e0d;
  border-color: #c56e0d;
}
</style>
